import React, { Component } from 'react';
import Highcharts from 'highcharts';

import HighchartsReact from 'highcharts-react-official';
import { observer, inject } from 'mobx-react';

class HumanPulseChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {},
            plotOptions: {
                line: {
                    threshold: 80,
                },
                series: {
                    enableMouseTracking: true,
                    fillColor: {
                        linearGradient: [0, 0, 0, 300],
                        stops: [
                            [0, '#f64866'],
                            [
                                1,
                                Highcharts.color('#bf0f24')
                                    .setOpacity(1)
                                    .get('rgba'),
                            ],
                        ],
                    },
                },
            },
        };
    }

    render() {
        const { centralStore } = this.props;
        let chartSize={

        };
        if(this.props.viewedFromTable){
            chartSize={
                height: 210,
                width:400,
                animation: {
                    duration: 1000,
                },
            };
        }else{
            chartSize={
                height: 210,
                animation: {
                    duration: 1000,
                },
            };
        }
    
        return (
            <>
                {centralStore.pulseChartDataValue.data &&
        centralStore.pulseChartDataValue.data.length ? (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: chartSize,
                                title: {
                                    text: '',
                                },
                                yAxis: {
                                    title: {
                                        text: 'Pulse (bpm)',
                                    },
                                    tickInterval: 10,
                                    plotLines: [
                                        {
                                            color: '#000000',
                                            width: 2,
                                            value: 51,
                                            zIndex: 5,
                                        },
                                        {
                                            color: '#000000',
                                            width: 2,
                                            value: 119,
                                            zIndex: 5,
                                        },
                                    ],
                                },
                                xAxis: {
                                    categories: centralStore.pulseChartDataValue.categories,
                                    title: {
                                        text:
                    'Showing ' +
                    centralStore.pulseChartDataValue.data.length +
                    ' Pulse Readings',
                                    },
                                },

                                plotOptions: this.state.plotOptions,
                                series: [
                                    {
                                        type: 'area',
                                        color: '#f64866',
                                        data: centralStore.pulseChartDataValue.data,
                                        name: 'Heart Rate',
                                        animation: {
                                            duration: 1000,
                                        },
                                    },
                                ],
                                legend: {
                                    enabled: false,
                                },
                            }}
                            immutable={true}
                        />
                    ) : (
                        <h2>No data fetched. Consider Changing filter..!</h2>
                    )}
            </>
        );
    }
  componentWillUnmount = () => {
      this.setState({ chartData: [] });
  };
}

export default inject('centralStore')(observer(HumanPulseChart));
