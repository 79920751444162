export const checkAllFieldsValid = (obj) => {
    let allvalid = true;
    for (var key in obj) {
        console.log('key==>', key);
        if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
            console.log('Error in Key', key);
            allvalid = false;
        }
    }
    console.log('End key==>', allvalid);

    return allvalid;
};

export const getDateDaysBefore = (days) => {
    var date = new Date();
    date.setDate(date.getDate() - days);
    // console.log("getDateDaysBeforedate===>", date);
    // console.log(
    //   "UTC FORMAT Time===>",
    //   date.getUTCHours(),
    //   ":",
    //   date.getUTCMinutes(),
    //   ":",
    //   date.getUTCSeconds()
    // );

    var year = date.getUTCFullYear();

    var month = (1 + date.getUTCMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getUTCDate().toString();
    day = day.length > 1 ? day : '0' + day;

    var finalDate = year + '-'+ month + '-' + day;
    // console.log("Daysss==>", days, "finalDate==>", finalDate);

    return finalDate;
};

export const getFormattedDate = (date, withhyphen, filterFormat) => {
    if (!date) {
        return '01/01/2000';
    }
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    // console.log("IN UTILS")
    if (withhyphen === true) {
        if (filterFormat === true) {
            return year + '-' + month + '-' + day;
        } else {
            return month + '-' + day + '-' + year;
        }
    } else {
        return month + '/' + day + '/' + year;
    }

};

const drawerWidth = 240;

export const styles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 99,
        color: '#25a4e4;',
    },

    root: {
        display: 'flex',
        height: '100%',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'center',
    },

    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
});
export const calculateAge = (dob) => {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
};
export const authorizeHeader = (header) => {
    let key = JSON.parse(localStorage.getItem('sessionData')).accessToken
        .jwtToken;
    if (header) {
        header.Authorization = key;
    } else {
        header = {
            Authorization: key,
        };
    }
    return header;
};

export const getCurrentEnvironment = () => {
    const hostname = window && window.location && window.location.hostname;
    console.log('Windows',window);
    console.log('window.location',window.location);
    console.log('window.location.hostname',window.location.hostname);
    console.log('hostname', hostname);
    let env = 'dev';
    if (hostname === 'localhost' || hostname === 'dev.mvs.services') {
        env = 'dev';
    } else if (hostname === 'stage.mvs.services' || hostname === 'mvshc.com' || hostname ==='stage.mvshc.com' || hostname === 'www.mvshc.com') {
        env = 'stage';
    } else if (hostname === 'mvs.services') {
        env = 'prod';
    }
    console.log('returned ENVT==>', env);
    return env;
};
