import { get, post, put, deleteapi } from './api';
import { baseURL } from './api-config';
import { StoreInstance } from '../centralstore';

export const addPatient = async (patientdetails) => {
    const body = {
        url: `${baseURL}/pd/patientdetails`,
        data: patientdetails,
    };
    await post(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ': ' + response.statusText
                );
            }
            throw response.json();
        }
    });
};

export const archivePatient = async (patientdetails) => {
    const body = {
        url: `${baseURL}/archive/archive?patient_id=${patientdetails.patient_id}&physician_id=${patientdetails.physician_id}`,
        data: patientdetails,
        headers: {}, 
    };
    await deleteapi(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ' :'  + response.statusText
                );
            }
            throw response.json();
        }
    });
};

export const getArchivePatientDetails = async () => {
    const body = {
        url:`${baseURL}/arc/list`,
        header:{}
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            }
            throw response.json();
        }
    });
};

export const getUnarchivePatientDetails = async (patientdetails) => {
    const body = {
        url: `${baseURL}/arc/unarchieve?physician_id=${patientdetails.physician_id}&patient_id=${patientdetails.patient_id}`,
        data: patientdetails,
    };
    await post(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':' + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            }
            throw response.json();
        }
    });
};


export const getPatientDetails = async (header, startDate, endDate) => {
    const body = {
        url: `${baseURL}/pd/patientdetails?limit=100&start_date=${startDate}&end_date=${endDate}`,
        headers: header,
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            }
        }
    });
};

export const getPatientDetailsById = async (patient_Id) => {
    const body = {
        url: `${baseURL}/pd/patientbyid?patient_id=${patient_Id}`,
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            console.log('IN ERROR', response.status);
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            }
        }
    });
};

export const getArchivePatientDetailsById = async (patient_Id) => {
    const body = {
        url: `${baseURL}/psu/patientArchive?patient_id=${patient_Id}&archive=1`,
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            console.log('IN ERROR', response.status);
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            }
        }
    });
};

export const updatePatient = async (patientdetails) => {
    const body = {
        url: `${baseURL}/pd/updatepatient`,
        data: patientdetails,
        headers: {},
    };
    await put(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            }
        }
    });
};

export const updatePatientProvider = async (providerdetails) => {
    const body = {
        url: `${baseURL}/pd/updateProvider`,
        data: providerdetails,
        headers: {},
    };
    await put(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            }
        }
    });
};

export const getPatientBloodOxygenReadings = async (humanId, filterRange) => {
    const body = {
        url: `${baseURL}/oxy/bloodoxygenbyid?humanId=${humanId}&start_date=${filterRange.startDate}&end_date=${filterRange.endDate}`,
        headers: {},
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            }
        }
    });
};

export const getPatientBPReadings = async (humanId, filterRange) => {
    const body = {
        url: `${baseURL}/bp/bloodpressureid?humanId=${humanId}&start_date=${filterRange.startDate}&end_date=${filterRange.endDate}`,
        headers: {},
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                ); 
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ' :'  + response.statusText
                );
            }
        }
    });
};

export const getPatientPulseReadings = async (humanId, filterRange) => {
    const body = {
        url: `${baseURL}/hr/getHRbyid?humanId=${humanId}&start_date=${filterRange.startDate}&end_date=${filterRange.endDate}`,
        headers: {},
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ' : '  + response.statusText
                );
            }
        }
    });
};

export const getPatientActivityReadings = async (humanId, filterRange) => {
    const body = {
        url: `${baseURL}/act/activitybyid?humanId=${humanId}&start_date=${filterRange.startDate}&end_date=${filterRange.endDate}`,
        headers: {},
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            }
        }
    });
};

export const getPatientGlucoseReadings = async (humanId, filterRange) => {
    const body = {
        url: `${baseURL}/glc/glucosebyid?humanId=${humanId}&start_date=${filterRange.startDate}&end_date=${filterRange.endDate}`,
        headers: {},
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            }
        }
    });
};

export const getPatientTemperatureReadings = async (humanId, filterRange) => {
    const body = {
        url: `${baseURL}/temp/temperaturebyid?humanId=${humanId}&start_date=${filterRange.startDate}&end_date=${filterRange.endDate}`,
        headers: {},
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            }
        }
    });
};

export const getPaientAggregateReadings = async () => {
    let humanId=localStorage.getItem('humanId');
    const body = {
        url: `${baseURL}/bp/getaggregate?humanId=${humanId}`,
        headers: {},
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            }
        }
    });
};
export const sendMessageToPatient=async (dataBody)=>{

    const body = {
        url: `${baseURL}/pd/sms`,
        data: dataBody,
    };
    await post(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':' + response.statusText
                );
            }
            throw response.json();
        }
    });
};

export const sendPdfToPatient=async (dataBody)=>{

    const body = {
        url: `${baseURL}/cn/emailReport`,
        data: dataBody,
    };
    await post(body).then((response) => {
        if (response) {
            console.log('sendPdfToPatientRESPONSE',response);
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':' + response.statusText
                );
            }
            throw response.json();
        }
    });
};

export const getChatMessageList = async () => {
    const body = {
        url: `${baseURL}/notify/message`,
    // headers: header,
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':' + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ' : '  + response.statusText
                );
            }
        }
    });
};

export const getDoctorsList = async () => {
    let physicianbyid = JSON.parse(localStorage.getItem('sessionData')).idToken.payload['custom:userId'];
    const body = {
        url: `${baseURL}/phy/physicianbyid?physicianId=${physicianbyid}`,
    // headers: header,
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ' :'  + response.statusText
                );
            }
        }
    });
};

export const sendMessageFromChatapp=async (dataBody)=>{

    const body = {
        url: `${baseURL}/notify/sms`,
        data: dataBody,
    };
    await post(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ' :'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ' : ' + response.statusText
                );
            }
            throw response.json();
        }
    });
};

export const scheduleMessageFromChatapp=async (dataBody)=>{
    const body = {
        url: `${baseURL}/rem/reminder`,
        data: dataBody,
    };
    await post(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ' : ' + response.statusText
                );
            }
            throw response.json();
        }
    });
};

export const broadcastMessageToPatients=async (dataBody)=>{

    // const body = {
    //   url: `${baseURL}/pd/sms`,
    //   data: dataBody,
    // };
    // await post(body).then((response) => {
    //   if (response.ok) {
    //     return response.json();
    //   } else {
    //     var errorMessage = "";
    //     if (response.status >= 500) {
    //       errorMessage = "Error From API Side. Report to Back End Developer...!";
    //       StoreInstance.errorHasOccured(
    //         errorMessage + " : " + response.statusText
    //       );
    //     } else {
    //       errorMessage = "Error From UI Side. Report to Front End Developer...!";
    //       StoreInstance.errorHasOccured(
    //         errorMessage + " : " + response.statusText
    //       );
    //     }
    //     throw response.json();
    //   }
    // });
};

