import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import Alert from '@material-ui/lab/Alert/Alert';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { observer, inject } from 'mobx-react';
import { getDateDaysBefore, getFormattedDate } from '../../common/util';
import { getChatMessageList, sendMessageFromChatapp, getPatientDetails, getDoctorsList, scheduleMessageFromChatapp } from '../../common/apicall/patient';
import Radio from '@material-ui/core/Radio';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

document.body.classList.remove('bodyStyle');

class ChatApp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            successMessage: '',
            patientName: '',
            messageList: [],
            message: [],
            editedMsg: [],
            number: [],
            numArr: [],
            mailArr: [],
            emails: [],
            radio1value: 'patient',
            radio2value: 'phone',
            filterValue: {},
            patientNumbers: [],
            doctorNumbers: [],
            patientMails: [],
            doctorMails: [],
            doctorName: '',
            checkedA: false,
            sDate: new Date(),
            patient_id: '',
            alertShow: false
        };
    }
    componentDidMount = async () => {
        this.filterValue = {
            startDate: getDateDaysBefore(30),
            endDate: getDateDaysBefore(0),
        };
        getPatientDetails({}, this.filterValue.startDate, this.filterValue.endDate)
            .then((data) => {
                data.data.forEach(patient => {
                    this.setState({ patient_id: patient.patient_id });
                    this.setState({ patientName: '(' + patient.lastName + ' ' + patient.firstName + ')  ' });
                    let mailArr = [];
                    mailArr.push(patient.email);
                    const uniquemail = Array.from(new Set(mailArr));
                    this.state.patientMails.push(this.state.patientName + uniquemail);

                    let noArr = [];
                    noArr.push(patient.phone);
                    const uniqueno = Array.from(new Set(noArr));
                    this.state.patientNumbers.push(this.state.patientName + uniqueno);
                });
            });


        getDoctorsList().then((data) => {
            this.setState({ doctorName: '(' + data.firstName + ' ' + data.lastName + ')  ' });
            let docMail = [];
            docMail.push(data.email);
            const uniquedocmail = Array.from(new Set(docMail));
            this.state.doctorMails.push(this.state.doctorName + uniquedocmail);

            let docNum = [];
            docNum.push(data.phone);
            var unique = docNum.filter((v, i, a) => a.indexOf(v) === i);
            this.state.doctorNumbers.push(this.state.doctorName + unique);
        }).catch((error) => {
            console.log('Error===>', error.errorMessage);
        });

        getChatMessageList().then((data) => {
            this.setState({ messageList: data.data[0].message_list });
        }).catch((error) => {
            console.log('Error===>', error.errorMessage);
        });
    };


    onMessageChange = (event) => {
        this.setState({ editedMsg: [] });
        this.setState({ message: event.target.value });
    };

    onEditMessage = (event) => {
        this.setState({ message: [] });
        this.setState({ editedMsg: event.target.value });
    };

    onMailChange = (event) => {
        event.target.value.forEach(element => {
            let no = element.split(' ');
            let mail = no[3];
            this.state.mailArr.push(mail);
        });
        this.setState({ emails: event.target.value });
    };
    onPhoneChange = (event) => {
        event.target.value.forEach(element => {
            let no = element.split(' ');
            let number = no[3];
            this.state.numArr.push(number);
        });
        this.setState({ number: event.target.value });
    };

    ///////////// ONCHANGE OPTIM
    handleChange = (evt) => {
        const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
        this.setState({ [evt.target.name]: value });
    }

    radio1Change = (event) => {
        this.setState({ radio1value: event.target.value });
    }
    radio2Change = (event) => {
        this.setState({ radio2value: event.target.value });
    }

    sendMessage = () => {
        let flag = this.state.radio2value;
        let dataBody = {};
        let scheduleFlag = this.state.checkedA;
        let t = this.state.sDate.toUTCString();
        let time = t.split(' ');
        let onlyTime = time[4];
        let dateObj = getFormattedDate(this.state.sDate, true, true) + ' ' + onlyTime;
        let msgToSend = [];
        let physicianbyid = JSON.parse(localStorage.getItem('sessionData')).idToken.payload['custom:userId'];

        if (this.state.editedMsg.length !== 0) {
            msgToSend.push(this.state.editedMsg);
        } else if (this.state.message.length !== 0) {
            this.state.message.forEach(element => {
                msgToSend.push(element);
            });
        }

        if (scheduleFlag === true) {
            if (flag === 'email') {
                dataBody = {
                    physician_id: physicianbyid,
                    patient_id: this.state.patient_id,
                    email_to_send: this.state.mailArr,
                    phone_to_send: this.state.numArr,
                    message_to_send: msgToSend,
                    time: dateObj,
                    flag: flag,
                    body_txt: 'message from mvs',
                    reminder_name: 'set reminder'
                };
            } else {
                dataBody = {
                    physician_id: physicianbyid,
                    patient_id: this.state.patient_id,
                    email_to_send: this.state.mailArr,
                    phone_to_send: this.state.numArr,
                    message_to_send: msgToSend,
                    time: dateObj,
                    flag: flag,
                    body_txt: 'message from mvs',
                    reminder_name: 'set reminder'
                };
            }
            scheduleMessageFromChatapp(dataBody).then((response) => {
                this.setState({ successMessage: 'Reminder set successfully', alertShow: true, editedMsg: [], message: [], number: [], emails: [] });
                setTimeout(() => {
                    this.setState({ successMessage: '', alertShow: false });
                }, 6000);
            });

        } else {
            if (flag === 'email') {
                dataBody = {
                    flag: flag,
                    subject: 'Reminder from MVS +',
                    receiver: this.state.mailArr,
                    message: msgToSend,
                    body_txt: 'message',
                };
            } else {
                dataBody = {
                    flag: flag,
                    list_of_phone: this.state.numArr,
                    message: msgToSend,
                };
            }
            sendMessageFromChatapp(dataBody).then((response) => {
                this.setState({ successMessage: 'Messsage sent successfully', alertShow: true, editedMsg: [], message: [], number: [], emails: [] });
                setTimeout(() => {
                    this.setState({ successMessage: '', alertShow: false });
                }, 6000);
            });
        }
    }

    handleDateChange = (date) => {
        this.setState({ sDate: date });
    };

    handleScheduleChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    }
    handleScheduleDialogClose = () => {
        const { centralStore } = this.props;
        centralStore.closeScheduleDialog();
    };

    render() {
        const { centralStore } = this.props;
        const { selectedPatientDataValue } = centralStore;
        // console.log("selectedPatientDataValue",toJS(selectedPatientDataValue))
        return (
            <div>
                <div className="schedule-popup">
                    <div className="schedule-popup-body">
                        <div className="schedule-popup-content">
                            <Box component="h3" mb={2} className="schedule-popup-heading">
                                Schedule Message
                            </Box>
                            <button
                                className="close-btn"
                                onClick={() => {
                                    centralStore.setShowSchedulePopup(false);
                                }}
                            >
                                <i className="icon-cancel"></i>
                            </button>
                           
                            {this.state.alertShow === true ? (
                                <Box mb={4}>
                                    <Alert severity="success">{this.state.successMessage}</Alert>
                                </Box>
                            ) : null}

                            <Grid
                                container
                                spacing={3}
                                className="profile-info"
                                justifyContent="center"
                            >
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Select Recipient Type :</FormLabel>
                                        <RadioGroup aria-label="patientDoc" name="patientDoc" value={this.state.radio1value} onChange={this.radio1Change} row>
                                            <FormControlLabel value="patient" control={<Radio />} label="Patient" />
                                            <FormControlLabel value="doctor" control={<Radio />} label="Doctor" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography fontSize="12" variant="h6">
                                        Select Message Template :
                                    </Typography>

                                    <FormControl className="chatapp-cntrl">
                                        <InputLabel id="demo-mutiple-chip-label">Select a message</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            fullWidth
                                            value={this.state.message}
                                            onChange={this.onMessageChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                        // MenuProps={MenuProps}
                                        >
                                            {this.state.messageList.map((msg) => (
                                                <MenuItem key={msg} value={msg}>
                                                    <Checkbox checked={this.state.message.indexOf(msg) > -1} />
                                                    <ListItemText primary={msg} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        label="Send custom message"
                                        fullWidth={true}
                                        type="username"
                                        id="message"
                                        value={this.state.editedMsg}
                                        onChange={this.onEditMessage}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Choose Phone or Email :</FormLabel>
                                        <RadioGroup aria-label="phoneMail" name="phoneMail" value={this.state.radio2value} onChange={this.radio2Change} row>
                                            <FormControlLabel value="phone" control={<Radio />} label="Phone" />
                                            <FormControlLabel value="email" control={<Radio />} label="Email" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={12} >
                                    {this.state.radio2value === 'email' ? (
                                        <Typography fontSize="12" variant="h6">
                                            {this.state.radio1value === 'patient' ? (
                                                '(' + selectedPatientDataValue.firstName + ' ' + selectedPatientDataValue.lastName + ')  ' + selectedPatientDataValue.email
                                            ) : (
                                                this.state.doctorMails[0]
                                            )}
                                        </Typography>
                                    ) : (
                                        <Typography fontSize="12" variant="h6">
                                            {this.state.radio1value === 'patient' ? (
                                                '(' + selectedPatientDataValue.firstName + ' ' + selectedPatientDataValue.lastName + ')  ' +selectedPatientDataValue.phone
                                            ) : (
                                                this.state.doctorNumbers[0]
                                            )}
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormGroup >
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.checkedA} onChange={this.handleScheduleChange} name="checkedA" />}
                                            label="Schedule To Send Later"
                                        />
                                    </FormGroup>
                                </Grid>

                                {this.state.checkedA === true ? (
                                    <Grid item xs={12} md={6}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker value={this.state.sDate} onChange={this.handleDateChange} />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                ) : ''}

                                <Grid item xs={12} >
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <Box mr={2}>
                                            {this.state.checkedA === true ? (
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={this.sendMessage}
                                                >
                                                    SCHEDULE
                                                </button>
                                            ) : (<button
                                                className="btn btn-primary"
                                                onClick={this.sendMessage}
                                            >
                                                SEND
                                            </button>)
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                         
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default inject('centralStore')(observer(ChatApp));