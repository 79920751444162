import './App.scss';
import { withRouter } from 'react-router-dom';
import Login from './components/pages/Login';
import Signup from './components/pages/Signup';
import ForgotPassword from './components/pages/ForgotPassword';
import Home from './components/pages/Home';
import PatientDashboard from './components/pages/PatientDashboard';
import O2SaturationPulseChart from './components/charts/O2SaturationPulseChart';
import HumanPulseChart from './components/charts/HumanPulseChart';
import BloodPressureChart from './components/charts/BloodPressureChart';
import React, { Component } from 'react';
import {inject } from 'mobx-react';
import AuthorizedRoute from './common/AuthorizedRoute';
import withSideBar from './common/SideBarHOC';
import UserProfile from './components/pages/UserProfile';
import ChatApp from './components/pages/ChatApp';
import ArchivedLists from './components/pages/ArchivedLists';
import ClientLists from './components/pages/ClientLists';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import ArchivePatientDashboard from './components/ArchivePatient/ArchivePatientDashboard';

export class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticating: true,
            user: null,
        };
    }

    render() {
        const { centralStore } = this.props;
        const HomeApp = withSideBar(Home);
        const PatientDashboardApp = withSideBar(PatientDashboard);
        const ArchiveDashboardApp = withSideBar(ArchivePatientDashboard);
        const UserProfileApp = withSideBar(UserProfile);
        const ChatApplication = withSideBar(ChatApp);
        const ArchivedListApp = withSideBar(ArchivedLists);
        const ClientListApp = withSideBar(ClientLists);

        return (
            <div className="App h-100">
                <React.Fragment>
                    <AuthorizedRoute
                        isUserAuthenticated={true}
                        exact
                        path="/login"
                        component={Login}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={true}
                        exact
                        path="/"
                        component={Login}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={true}
                        exact
                        path="/forgotPassword"
                        component={ForgotPassword}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={true}
                        exact
                        path="/signup"
                        component={Signup}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={centralStore.isUserCurrentlyAuthenticated}
                        exact
                        path="/humanPulseChart"
                        component={HumanPulseChart}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={centralStore.isUserCurrentlyAuthenticated}
                        exact
                        path="/pulseChart"
                        component={O2SaturationPulseChart}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={centralStore.isUserCurrentlyAuthenticated}
                        exact
                        path="/bpChart"
                        component={BloodPressureChart}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={centralStore.isUserCurrentlyAuthenticated}
                        path="/home"
                        component={HomeApp}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={centralStore.isUserCurrentlyAuthenticated}
                        path="/patientdashboard"
                        component={PatientDashboardApp}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={centralStore.isUserCurrentlyAuthenticated}
                        path="/archivedashboard"
                        component={ArchiveDashboardApp}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={centralStore.isUserCurrentlyAuthenticated}
                        path="/userprofile"
                        component={UserProfileApp}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={centralStore.isUserCurrentlyAuthenticated}
                        path="/chatapp"
                        component={ChatApplication}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={centralStore.isUserCurrentlyAuthenticated}
                        path="/archivedlists"
                        component={ArchivedListApp}
                    />
                    <AuthorizedRoute
                        isUserAuthenticated={centralStore.isUserCurrentlyAuthenticated}
                        path="/clientlist"
                        component={ClientListApp}
                    />
                </React.Fragment>
            </div>
        );
    }
}

export default withRouter(inject('centralStore')((App)));
