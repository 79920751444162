import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import config from './config';
import { Provider } from 'mobx-react';
import { StoreInstance } from './common/centralstore';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { getCurrentEnvironment } from './common/util';
Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config['cognito'][getCurrentEnvironment()]['REGION'],
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: config['cognito'][getCurrentEnvironment()]['USER_POOL_ID'],
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: config['cognito'][getCurrentEnvironment()]['APP_CLIENT_ID'],
        identityPoolId:config['cognito'][getCurrentEnvironment()]['IDENTITY_POOL_ID']

    },
    Storage: {
        bucket:  config['cognito'][getCurrentEnvironment()]['BUCKET_NAME'], 
        region: config['cognito'][getCurrentEnvironment()]['REGION'], //OPTIONAL -  Amazon service region
        identityPoolId: config['cognito'][getCurrentEnvironment()]['IDENTITY_POOL_ID']
    }
});
ReactDOM.render(
    <Provider centralStore={StoreInstance}>
        <Router>
            <Switch>
                <App />
            </Switch>
        </Router>
    </Provider>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
