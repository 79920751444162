import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Typography from '@material-ui/core/Typography';

export class ArchiveEmergencyInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
        };
    }

    render() {
        const { centralStore } = this.props;
        const {
            provider,
            relativename,
            relativenumber
        } = centralStore.selectedArchivePatientDataValue;

        console.log('arc em',centralStore.selectedArchivePatientDataValue);
        return (
            <div>
                <Typography fontSize="12" variant="h6">
          Patient's Care Giver : {relativename}
                </Typography>
                <Typography fontSize="12" variant="h6" >
          Care Giver Contact Info : {relativenumber}
                </Typography>
                <Typography fontSize="12" variant="h6" >
          Doctor's Info : {provider.name}
                </Typography>
                <Typography fontSize="12" variant="h6" >
          Doctor's Contact Info : {provider['custom:phone']}
                </Typography>
            </div>
        );
    }
}

export default inject('centralStore')(observer(ArchiveEmergencyInfo));