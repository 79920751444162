import { getPatientDetails, getPatientDetailsById,getArchivePatientDetails, getArchivePatientDetailsById } from './apicall/patient';
import { StoreInstance } from './centralstore';
import {
    setActivityChartDataWithFilter,
    setBPChartDataWithFilter,
    setGlucoseChartDataWithFilter,
    setO2SaturationChartDataWithFilter,
    setPulseChartDataWithFilter,
    setTemperatureChartDataWithFilter,
} from './apicall/chart-utils';
import { getPhysicianDetails } from './apicall/physician';

export const setPatientTableWithFilter = (filterValue) => {
    StoreInstance.showLoader();
    StoreInstance.setHasMore(true);
    getPatientDetails({}, filterValue.startDate, filterValue.endDate)
        .then((data) => {
            console.log('set18',data);
            StoreInstance.setPatientTableData(data.data);
            let key = data.lastEvaluatedKey;
            console.log('key',key);
            localStorage.setItem('lastevaluatedkey', JSON.stringify(key));
            console.log('22 patient',JSON.stringify(key));
            if (key === 'NA') {
                StoreInstance.setHasMore(false);
            } else {
                StoreInstance.setHasMore(true);
            }
            StoreInstance.hideLoader();
        })
        .catch((error) => {
            console.log('Error===>', error.errorMessage);
            StoreInstance.hideLoader();
        });
};

export const getNextPatientData = () => {
    let header = {
        'Content-Type': 'application/json',
        Accept: '*/*',
    };
    header.lastevaluatedkey = localStorage.getItem('lastevaluatedkey');
    getPatientDetails(header)
        .then((data) => {
            console.log(('lastEvaluatedKey', JSON.stringify(data.lastEvaluatedKey)));
            let key = JSON.stringify(data.lastEvaluatedKey);
            localStorage.setItem('lastevaluatedkey', key);
            if (data.data.length > 0) {
                StoreInstance.setHasMore(true);
                StoreInstance.addNewPatientData(data.data);
            } else {
                StoreInstance.setHasMore(false);
            }
            StoreInstance.hideLoader();
        })
        .catch((error) => {
            console.log('Error===>', error.errorMessage);
            StoreInstance.hideLoader();
        });
};

export const setArchivePatientTable = () => {
    StoreInstance.showLoader();
    StoreInstance.setHasMore(true);
    getArchivePatientDetails()
        .then((data) => {
            console.log('68',data);
            StoreInstance.setArchivedPatientTableData(data.data);
            let key = data;
            console.log('70 key',key);
            localStorage.setItem('lastarcevaluatedkey', JSON.stringify(key.data));
            if (key) {
                StoreInstance.setHasMore(false);
            } else {
                StoreInstance.setHasMore(true);
            }
            StoreInstance.hideLoader();
        })
        .catch((error) => {
            console.log('Error===>', error.errorMessage);
            StoreInstance.hideLoader();
        });
};
export const getNextArchivePatientData = () => {
    let header = {
        'Content-Type': 'application/json',
        Accept: '*/*',
    };
    header.lastevaluatedkey = localStorage.getItem('lastarcevaluatedkey');

    getArchivePatientDetails(header)
        .then((data) => {
            console.log('resp', data);
            console.log(('lastevaluatedkey', JSON.stringify(data)));
            let key = JSON.stringify(data);
            localStorage.setItem('lastevaluatedkey', key);
            if (data.data.length > 0) {
                StoreInstance.setHasMore(true);
            } else {
                StoreInstance.setHasMore(false);
            }
            StoreInstance.hideLoader();
        })
        .catch((error) => {
            console.log('Error===>', error.errorMessage);
            StoreInstance.hideLoader();
        });
};


export const setCurrentPatientData = async () => {
    let patient_id = localStorage.getItem('patient_id');
    StoreInstance.showLoader();

    await getPatientDetailsById(patient_id)
        .then((data) => {
            console.log('set118',data);
            StoreInstance.setSelectedPatientData(data);
            console.log('120===>', data.humanId);
            localStorage.setItem('humanId', data.humanId);
            StoreInstance.hideLoader();
        })
        .catch((error) => {
            console.log('Error===>', error.errorMessage);
            StoreInstance.hideLoader();
        });
};

export const setCurrentArchivePatientData = async () => {
    let patient_id = localStorage.getItem('archive_patient_id');
    StoreInstance.showLoader();

    await getArchivePatientDetailsById(patient_id)
        .then((data) => {
            StoreInstance.setSelectedArchivePatientData(data.data.Items[0]);
            localStorage.setItem('humanId', data.data.Items[0].humanId);
            StoreInstance.hideLoader();
        })
        .catch((error) => {
            StoreInstance.hideLoader();
        });
};

export const setPhysicianTable = () => {
    StoreInstance.showLoader();
    StoreInstance.setHasMore(true);
    getPhysicianDetails({})
        .then((data) => {
            console.log('resp', data);
            StoreInstance.setPhysicianTableData(data.data);
            let key = data;
            console.log('136',key);
            localStorage.setItem('lastphysicianevaluatedkey', JSON.stringify(key));
            if (key) {
                StoreInstance.setHasMore(false);
            } else {
                StoreInstance.setHasMore(true);
            }
            StoreInstance.hideLoader();
        })
        .catch((error) => {
            console.log('Error===>', error.errorMessage);
            StoreInstance.hideLoader();
        });
};

export const getNextPhysicianData = () => {
    let header = {
        'Content-Type': 'application/json',
        Accept: '*/*',
    };
    header.lastevaluatedkey = localStorage.getItem('lastphysicianevaluatedkey');
    getPhysicianDetails(header)
        .then((data) => {
            console.log('resp', data);
            console.log(('lastevaluatedkey', JSON.stringify(data)));
            let key = JSON.stringify(data);
            localStorage.setItem('lastphysicianevaluatedkey', key.data);
            if (data.data.length > 0) {
                StoreInstance.setHasMore(true);
                StoreInstance.addNewPhysicianData(data.data);
            } else {
                StoreInstance.setHasMore(false);
            }
            StoreInstance.hideLoader();
        })
        .catch((error) => {
            console.log('Error===>', error.errorMessage);
            StoreInstance.hideLoader();
        });
};


export const setChartData = async (filterValue) => {
    let humanId = localStorage.getItem('humanId');
    await setActivityChartDataWithFilter(humanId, filterValue);
    await setGlucoseChartDataWithFilter(humanId, filterValue);
    await setBPChartDataWithFilter(humanId, filterValue);
    await setTemperatureChartDataWithFilter(humanId, filterValue);
    await setPulseChartDataWithFilter(humanId, filterValue);
    await setO2SaturationChartDataWithFilter(humanId, filterValue);
};
