import React, {useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { observer, inject } from 'mobx-react';
// import { getPaientAggregateReadings } from "../../common/apicall/patient";
import { TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
const INITIAL_INPUT_STATE={
    SBP_MIN:'91',
    SBP_MAX:'139',
    DBP_MIN:'51',
    DBP_MAX:'98',
    HR_MIN:'51',
    HR_MAX:'119',
    MIN_WEIGHT:'45',
    MAX_WEIGHT:'100',
    OXYGEN_MIN:'95',
    OXYGEN_MAX:'100',
    GLUCOSE_MIN:'93',
    GLUCOSE_MAX:'100'
};

const message='Please repeat your vitals, if it remains abnormal contact to your Physician or go to the ER. Thank You';
const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

const AlarmPopup=(props)=>{
    const {centralStore}=props;
    const [checked,setChecked]=useState();
    const [inputsValue, setInputs] = useState(INITIAL_INPUT_STATE);

    function onChange(event) {
        const newValue = event.target.value;
        const inputName = event.target.name;
        setInputs({...inputsValue,[inputName]:newValue});
        console.log('input', inputsValue);
    }

    const {
        firstName,
        lastName,
        phone,
        email,
        provider,
        dob,
    } = centralStore.selectedPatientDataValue;

    const handleChange = (event) => {
        setChecked(event.target.checked);
        console.log('hecked value', checked);
    };


    return(
        <div>
            <div className="reading-popup">
                <div className="reading-popup-body">
                    <div className="reading-popup-content">
                        <Box component="h3" mb={2} className="reading-popup-heading">
           Configure Alarm(Threshold value)
                        </Box>
                        <Box component="h3" mb={2} className="reading-popup-heading">
           Patient Name:{`${lastName} ${firstName}`}
                        </Box>
          
                        <button
                            className="close-btn"
                            onClick={() => {
               
                                centralStore.setShowAlarmPopup(false);
                            }}
                        >
                            <i className="icon-cancel"></i>
                        </button>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Paper className="gray-box">
                                    <Box component="h4" mb={2}>
                  Blood Pressure
                                    </Box>
                                    <Box display="flex">
                                        <Box display="flex" flexGrow="1" flexDirection="column">
                                            <div className="reading-values">
                                                <div className="reading-values-box">
                        
                                                    <TextField fullWidth size="small" id="outlined-basic" label="SBP MIN" variant="outlined"  value={ inputsValue['SBP_MIN']}name="SBP_MIN" onChange={onChange}/>
                                                </div>
                                                <div className="reading-values-box">
                        
                                                    <TextField fullWidth size="small" id="outlined-basic" label="DBP MIN" variant="outlined"  value={inputsValue['DBP_MIN']} name="DBP_MIN" onChange={onChange} />
                                                </div>
                                            </div>
                                            <div className="reading-values">
                                                <div className="reading-values-box">
                    
                                                    <TextField fullWidth size="small" id="outlined-basic" label="SBP MAX" variant="outlined"  value={ inputsValue['SBP_MAX']} name="SBP_MAX" onChange={onChange}/>
                                                </div>
                                                <div className="reading-values-box">
                        
                                                    <TextField fullWidth size="small" id="outlined-basic" label="DBP MAX" variant="outlined"  value={ inputsValue['DBP_MAX']} name="DBP_MAX" onChange={onChange}/>
                                                </div>
                                            </div>
                
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Paper className="gray-box">
                                    <Box component="h4" mb={2}>
                  Heart Rate
                                    </Box>
                                    <Box display="flex">
                                        <Box display="flex" flexGrow="1" flexDirection="column">
                                            <div className="reading-values">
                                                <div className="reading-values-box">
                                                    <TextField fullWidth size="small" id="outlined-basic" label="MIN" variant="outlined"  value={ inputsValue['HR_MIN']} name="HR_MIN" onChange={onChange}/>
                                                </div>
                                                <div className="reading-values-box">
            
                                                    <TextField fullWidth size="small" id="outlined-basic" label="MAX" variant="outlined"  value={ inputsValue['HR_MAX']} name="HR_MAX" onChange={onChange}/>
                                                </div>

                                            </div>
                    
                                          
                    
                                        </Box>
                 
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Paper className="gray-box">
                                    <Box component="h4" mb={2}>
                  Weight
                                    </Box>
                                    <Box display="flex">
                                        <Box display="flex" flexGrow="1" flexDirection="column">
                                            <div className="reading-values">
                                                <div className="reading-values-box">
                       
                                                    <TextField fullWidth size="small" id="outlined-basic" label="MIN" variant="outlined"  value={ inputsValue['MIN_WEIGHT']} name="MIN_WEIGHT" onChange={onChange}/>
                                                </div>
                                                <div className="reading-values-box">
                     
                                                    <TextField fullWidth size="small" id="outlined-basic" label="MAX" variant="outlined"  value={ inputsValue['MAX_WEIGHT']}name="MAX_WEIGHT" onChange={onChange}/>
                                                </div>

                                            </div>
                   
                                        </Box>
                 
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Paper className="gray-box">
                                    <Box component="h4" mb={2}>
                 Oxygen level
                                    </Box>
                                    <Box display="flex">
                                        <Box display="flex" flexGrow="1" flexDirection="column">
                                            <div className="reading-values">
                                                <div className="reading-values-box">
                       
                                                    <TextField fullWidth id="outlined-basic"size="small" label="MIN" variant="outlined"  value={ inputsValue['OXYGEN_MIN']} name="OXYGEN_MIN" onChange={onChange}/>
                                                </div>
                                                <div className="reading-values-box">
                     
                                                    <TextField fullWidth id="outlined-basic" size="small" label="MAX" variant="outlined"  value={ inputsValue['OXYGEN_MAX']}name="OXYGEN_MAX" onChange={onChange}/>
                                                </div>
                                            </div>                   
                                        </Box>
                 
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Paper className="gray-box">
                                    <Box component="h4" mb={2}>
                  Glucose
                                    </Box>
                                    <Box display="flex">
                                        <Box display="flex" flexGrow="1" flexDirection="column">
                                            <div className="reading-values">
                                                <div className="reading-values-box">
                       
                                                    <TextField fullWidth id="outlined-basic"  size="small"label="MIN" variant="outlined"  value={ inputsValue['GLUCOSE_MIN']} name="GLUCOSE_MIN" onChange={onChange}/>
                                                </div>
                                                <div className="reading-values-box">
                     
                                                    <TextField fullWidth id="outlined-basic" size="small" label="MAX" variant="outlined"  value={inputsValue['GLUCOSE_MAX']} name="GLUCOSE_MAX" onChange={onChange}/>
                                                </div>
                                            </div>
    
                   
                                        </Box>
                 
                                    </Box>
                                </Paper>
                            </Grid>
            
                        </Grid>

                        <Box my={2}>
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>Off</Grid>
                                <Grid item>
                                    <AntSwitch checked={checked} onChange={handleChange} name="checkedC" />
                                </Grid>
                                <Grid item>On</Grid>
                            </Grid>
                        </Box>
                        <>
                            {checked && <TextField fullWidth id="outlined-basic" label="Message"  size="small" variant="outlined"  value={message} name=""/>}
                        </>
                        <Box textAlign="left" pt={1} pb={3}>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
               
                                    centralStore.setShowAlarmPopup(false);
                                }}
                                color="primary"
                            >
                  Save
                            </button>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default inject('centralStore')(observer(AlarmPopup));
