import { computed, decorate, observable, action } from 'mobx';
import 'mobx-react-lite/batchingForReactDom';
import { getDateDaysBefore } from './util';
export class CentralStore {
  //UserInfo Vars
  currentUser = null;
  isUserAuthenticated = true;
  setCurrentUser = (newValue) => {
      this.currentUser = newValue;
  };
  get currentUserValue() {
      return this.currentUser;
  }

  get isUserCurrentlyAuthenticated() {
      return this.isUserAuthenticated;
  }
  setUserAuthenticated = (newValue) => {
      this.isUserAuthenticated = newValue;
  };


  showAlarmPopup=false;
  
  setShowAlarmPopup = (newValue) => {
      this.showAlarmPopup = newValue;
  };
  
  get currentShowAlarmPopup() {
      return this.showAlarmPopup;
  }
  //Flags for SideNavigation
  openNavigationDrawer = true;

  get currentOpenNavigationDrawerValue() {
      return this.openNavigationDrawer;
  }

  setNavigationDrawerOpen = (newValue) => {
      this.openNavigationDrawer = newValue;
  };

  //Flag for Reading Popup
  showReadingPopup = false;

  get currentShowReadingPopup() {
      return this.showReadingPopup;
  }

  setShowReadingPopup = (newValue) => {
      this.showReadingPopup = newValue;
  };
  //Flag for loaders

  loader = false;

  get currentloaderValue() {
      return this.loader;
  }
  showLoader = () => {
      this.loader = true;
  };
  hideLoader = () => {
      this.loader = false;
  };

  authLoader = false;

  get currentAuthloaderValue() {
      return this.authLoader;
  }
  showAuthLoader = () => {
      this.authLoader = true;
  };
  hideAuthLoader = () => {
      this.authLoader = false;
  };

  //Flag for errors
  errorOccured = false;
  errorMessage = undefined;
  errorHasOccured = (newValue) => {
      if (newValue) {
          this.errorOccured = true;
          this.errorMessage = newValue;
      } else {
          this.errorOccured = false;
      }
  };

  get hasErrorOccured() {
      return this.errorOccured;
  }

  get currentErrorMessage() {
      return this.errorMessage;
  }
//Sms Schedule Popup flag
scheduleDialog=false;
  
get openScheduleDialogValue(){
    return this.scheduleDialog;
}
openScheduleDialog=()=>{
    this.scheduleDialog=true;
}
closeScheduleDialog=()=>{
    this.scheduleDialog=false;
}

setShowSchedulePopup = (newValue) => {
    this.scheduleDialog = newValue;
};

  //Sms Email Popup flag
  messageDialog=false;
  
  get openMessageDialogValue(){
      return this.messageDialog;
  }
  openMessageDialog=()=>{
      this.messageDialog=true;
  }
  closeMessageDialog=()=>{
      this.messageDialog=false;
  }
  //Patient Data
  patientTableData = [];
  get currentPatientTableDataValue() {
      console.log('130',this.patientTableData);
      return this.patientTableData;
  }
  setPatientTableData = (newValue) => {
      this.patientTableData = newValue;
  };

  addNewPatientData = (newElementsArray) => {
      this.setPatientTableData(this.patientTableData.concat(newElementsArray));
  };

  //Archived Patient Data
   archivedPatientTableData = [];
   get archivedPatientTableDataValue(){
       console.log('144',this.archivedPatientTableData);
       return this.archivedPatientTableData;
   }
  
   setArchivedPatientTableData=(newValue)=>{
       this.archivedPatientTableData=newValue;
   }

  // Unarchived Patient Data
  unarchivedPatientTableData=[]
  
  get unarchivedPatientDataValue(){
      return this.unarchivedPatientTableData;
  }
  setUnarchiveData = (newValue)=>{
      this.unarchivedPatientTableData=newValue;
  }

  // Physician Data
  physicianTableData = []
  get physicianTableDataValue(){
      console.log('168 central',this.physicianTableData); 
      return this.physicianTableData;
  }
  setPhysicianTableData=(newValue)=>{
      this.physicianTableData=newValue;
  }
  addNewPhysicianData=(newElementsArray)=>{
      this.setPhysicianTableData(this.physicianTableData.concat(newElementsArray));
  }
  
  // dashboard patient data
  selectedPatientData = {
      firstName: ' ',
      lastName: '',
      patient_id: '',
      phone: '',
      email: '',
      relativename: '',
      relativenumber: '',
      provider: { name: '', email: '' },
      dob: '',
  };
  get selectedPatientDataValue() {
      console.log('this.selectedPatientData',this.selectedPatientData);
      return this.selectedPatientData;
  }

  setSelectedPatientData = (newValue) => {
      this.selectedPatientData = newValue;
  };
  
  // dashboard Archive data
  selectedArchivePatientData = {
      firstName: ' ',
      lastName: '',
      patient_id: '',
      phone: '',
      email: '',
      relativename: '',
      relativenumber: '',
      provider: { name: '', email: '' },
      dob: '',

      activity: {duration:'', steps:'', calories: ''},
      age: '',
      blood_pressure: {SBP: '', unit: '', DBP: ''},
      clientUserId: '',
      day: '',
      glucose: {value: '', unit: ''},
      health_criticality: '',
      humanId: '',
      month: '',
      o2_saturation: {value: '', unit: ''},
      physician_id: '',
      pulse: {value: '', unit: ''},
      relativeaddress: '',
      temperature: {valueF: '', unit: '', value: '', unitF: ''},
      weight: {unit: '', unit_lb: '', value: '', value_lb: ''},
      year: '',
  };
  get selectedArchivePatientDataValue() {
      return this.selectedArchivePatientData;
  }

setSelectedArchivePatientData = (newValue) => {
    this.selectedArchivePatientData = newValue;
};
  // dashboard Physician data
  selectedPhysicianData ={
      firstName:'',
      lastName:'',
      physician_id:'',
      phone:'',
      email:'',
      speciality:'',
      address:'',
      age:'',
      password:'',
      role:'',
      emailId:'',
      profileImage:''
  }

  get selectedPhysicianDataValue(){
      return this.selectedPhysicianData;
  }

  setSelectedPhysicianData = (newValue)=>{
      this.selectedPhysicianData=newValue;
  }

  //Flags for Add and Edit Dialog
  openDialog = false;

  get currentopenDialogValue() {
      return this.openDialog;
  }

  showAddEditDialog = () => {
      this.openDialog = true;
  };

  hideAddEditDialog = () => {
      this.openDialog = false;
  };

  // patient edit
  patientDataForEdit = undefined;

  get currentPatientDataForEditValue() {
      return this.patientDataForEdit;
  }

  setPatientDataForEdit = (newValue) => {
      this.patientDataForEdit = newValue;
  };

  
  // Physician edit data 

  physicianDataForEdit = undefined
  
  get currentPhysicianDataForEditValue(){
      return this.physicianDataForEdit;
  } 
   setPhysicianDataForEdit= (newValue)=>{
       this.physicianDataForEdit = newValue;
   }
 
  // load more
  hasMore = true;

  get currentHasMoreValue() {
      return this.hasMore;
  }

  setHasMore = (newValue) => {
      this.hasMore = newValue;
  };

  //Filter
  filter = {
      startDate: getDateDaysBefore(30),
      endDate: getDateDaysBefore(0),
  };

  get filterValue() {
      return this.filter;
  }

  setFilterValue = (newValue) => {
      this.filter = newValue;
  };

  //flag for Report Dialog
  openReportDialog = false;

  get currentOpenReportDialogValue() {
      return this.openReportDialog;
  }

  showReportDialog = () => {
      console.log('showReportDialog');
      this.openReportDialog = true;

  };

  hideReportDialog = () => {
      console.log('hideReportDialog');
      this.openReportDialog = false;
  };
  //Visible Charts Count
  visibleCharts=0
  get numberOfVisibleCharts() {
      return this.visibleCharts;
  }
  setNumberOfVisibleCharts=(newValue)=>{
      this.visibleCharts=newValue;
  }


  incrementVisibleeCharts=()=>{
      this.visibleCharts++;
  }
  decrementVisibleeCharts=()=>{
      this.visibleCharts--;
  }
  //Chart Data====>
  activityChartData = [];
  bpChartData = [];
  temperatureChartData = [];
  glucoseChartData = [];
  o2SaturationChartData = [];
  pulseChartData = [];

  get activityChartDataValue() {
      return this.activityChartData;
  }
  get bpChartDataValue() {
      return this.bpChartData;
  }
  get temperatureChartDataValue() {
      return this.temperatureChartData;
  }
  get glucoseChartDataValue() {
      return this.glucoseChartData;
  }
  get o2SaturationChartDataValue() {
      return this.o2SaturationChartData;
  }
  get pulseChartDataValue() {
      console.log('pulse',this.pulseChartData);
      return this.pulseChartData;
  }

  setActivityChartData = (newValue) => {
      this.activityChartData = newValue;
  };
  setTemperatureChartData = (newValue) => {
      this.temperatureChartData = newValue;
  };
  setGlucoseChartData = (newValue) => {
      this.glucoseChartData = newValue;
  };
  setBPChartData = (newValue) => {
      this.bpChartData = newValue;
  };
  setO2SaturationChartData = (newValue) => {
      this.o2SaturationChartData = newValue;
  };
  setPulseChartData = (newValue) => {
      this.pulseChartData = newValue;
  };
}

const DecoratedStore = decorate(CentralStore, {
    currentUser: observable,
    isUserAuthenticated: observable,
    openNavigationDrawer: observable,
    showReadingPopup: observable,
    showAlarmPopup:observable,
    loader: observable,
    authLoader: observable,
    errorMessage: observable,
    errorOccured: observable,
    patientTableData: observable,
    selectedPatientData: observable,
    openDialog: observable,
    patientDataForEdit: observable,
    hasMore: observable,
    messageDialog:observable,
    filter:observable,
    openReportDialog:observable,
    scheduleDialog:observable,
    visibleCharts:observable,
    archivedPatientTableData:observable,
    physicianTableData:observable,
    physicianDataForEdit:observable,
    selectedPhysicianData:observable,
    selectedArchivePatientData:observable,

    //computed
    currentUserValue: computed,
    isUserCurrentlyAuthenticated: computed,
    currentOpenNavigationDrawerValue: computed,
    currentShowReadingPopup: computed,
    currentShowAlarmPopup:computed,
    currentloaderValue: computed,
    currentAuthloaderValue: computed,
    hasErrorOccured: computed,
    currentErrorMessage: computed,
    currentPatientTableDataValue: computed,
    currentopenDialogValue: computed,
    currentPatientDataForEditValue: computed,
    selectedPatientDataValue: computed,
    currentHasMoreValue: computed,
    openMessageDialogValue:computed,
    filterValue:computed,
    currentOpenReportDialogValue:computed,
    openScheduleDialogValue:computed,
    numberOfVisibleCharts:computed,
    archivedPatientTableDataValue:computed,
    physicianTableDataValue:computed,
    currentPhysicianDataForEditValue:computed,
    selectedPhysicianDataValue:computed,
    selectedArchivePatientDataValue:computed,

    //actions
    setCurrentUser: action,
    setUserAuthenticated: action,
    setNavigationDrawerOpen: action,
    setShowReadingPopup: action,
    setShowAlarmPopup:action,
    showLoader: action,
    hideLoader: action,
    showAuthLoader: action,
    hideAuthLoader: action,
    showAddEditDialog: action,
    hideAddEditDialog: action,
    errorHasOccured: action,
    setPatientTableData: action,
    addNewPatientData: action,
    setArchivedPatientTableData:action,
    setUnarchiveData:action,
    setPhysicianTableData:action,
    setPatientDataForEdit: action,
    setSelectedPatientData: action,
    setHasMore: action,
    openMessageDialog:action,
    closeMessageDialog:action,
    openScheduleDialog:action,
    closeScheduleDialog:action,
    setFilterValue:action,
    showReportDialog:action,
    hideReportDialog:action,
    incrementVisibleeCharts:action,
    decrementVisibleeCharts:action,
    addNewPhysicianData:action,
    setPhysicianDataForEdit:action,
    setSelectedPhysicianData:action,
    setSelectedArchivePatientData:action,

    //==================ChartData======================
    activityChartData: observable,
    bpChartData: observable,
    temperatureChartData: observable,
    glucoseChartData: observable,
    o2SaturationChartData: observable,
    pulseChartData: observable,

    activityChartDataValue: computed,
    bpChartDataValue: computed,
    temperatureChartDataValue: computed,
    glucoseChartDataValue: computed,
    o2SaturationChartDataValue: computed,
    pulseChartDataValue: computed,

    setBPChartData: action,
    setActivityChartData: action,
    setTemperatureChartData: action,
    setGlucoseChartData: action,
    setO2SaturationChartData: action,
    setPulseChartData: action,
});
export const StoreInstance = new DecoratedStore();
