import { StoreInstance } from '../centralstore';
import { getDateDaysBefore } from '../util';
import {
    getPatientActivityReadings,
    getPatientBloodOxygenReadings,
    getPatientGlucoseReadings,
    getPatientPulseReadings,
    getPatientBPReadings,
    getPatientTemperatureReadings
} from './patient';

export const setActivityChartDataWithFilter = (humanId, filterRangeValue) => {
    getPatientActivityReadings(humanId, filterRangeValue).then((data) => {
        let chartData = data.data;
        if(chartData.length)
        {
            StoreInstance.incrementVisibleeCharts();
        }
        chartData.forEach((record, index) => {
            let timestamp = record.timestamp;

            if (filterRangeValue.startDate === getDateDaysBefore(0)) {
                record.timestamp =
          `(${index})` +
          timestamp.split('T')[1].split(':')[0] +
          ':' +
          timestamp.split('T')[1].split(':')[1];
            } else {
                record.timestamp =
          `(${index})` +
          timestamp.split('T')[0].split('-')[1] +
          '-' +
          timestamp.split('T')[0].split('-')[2];
            }
        });

        StoreInstance.setActivityChartData(chartData);
    });
};

export const setTemperatureChartDataWithFilter = (
    humanId,
    filterRangeValue
) => {
    getPatientTemperatureReadings(humanId, filterRangeValue).then((data) => {
        let chartData = data.data;
        if(chartData.length)
        {
            StoreInstance.incrementVisibleeCharts();
        }
        // console.log("Temperature=>", chartData);
        chartData.forEach((record) => {
            let timestamp = record.timestamp;

            if (filterRangeValue.startDate === getDateDaysBefore(0)) {
                record.timestamp =
          timestamp.split('T')[1].split(':')[0] +
         ':' +
          timestamp.split('T')[1].split(':')[1];
            } else {
                record.timestamp =
          timestamp.split('T')[0].split('-')[1] +
          '-' +
          timestamp.split('T')[0].split('-')[2];
            }
        });

        StoreInstance.setTemperatureChartData(chartData);
    });

};

export const setGlucoseChartDataWithFilter = (humanId, filterRangeValue) => {
    getPatientGlucoseReadings(humanId, filterRangeValue).then((data) => {
        let chartData = data.data;
        if(chartData.length)
        {
            StoreInstance.incrementVisibleeCharts();
        }
        // console.log("Glucose=>", data);

        chartData.forEach((record) => {
            let timestamp = record.timestamp;

            if (filterRangeValue.startDate === getDateDaysBefore(0)) {
                record.timestamp =
          timestamp.split('T')[1].split(':')[0] +
          ':' +
          timestamp.split('T')[1].split(':')[1];
            } else {
                record.timestamp =
          timestamp.split('T')[0].split('-')[1] +
          '-' +
          timestamp.split('T')[0].split('-')[2];
            }
        });

        StoreInstance.setGlucoseChartData(chartData);
    });
};
export const setBPChartDataWithFilter = (humanId, filterRangeValue) => {
    getPatientBPReadings(humanId, filterRangeValue).then((data) => {
        let chartData = data.data;
        if(chartData.length)
        {
            StoreInstance.incrementVisibleeCharts();
        }
        // console.log("BPDATA=>", data);

        chartData.forEach((record) => {
            let timestamp = record.timestamp;

            if (filterRangeValue.startDate === getDateDaysBefore(0)) {
                record.timestamp =
          timestamp.split('T')[1].split(':')[0] +
          ':' +
          timestamp.split('T')[1].split(':')[1];
            } else {
                record.timestamp =
          timestamp.split('T')[0].split('-')[1] +
          '-' +
          timestamp.split('T')[0].split('-')[2];
            }
        });

        StoreInstance.setBPChartData(chartData);
    });
};
export const setO2SaturationChartDataWithFilter = (
    humanId,
    filterRangeValue
) => {
    getPatientBloodOxygenReadings(humanId, filterRangeValue).then((data) => {
    // console.log("DATAIn pulse====>", data);
        let chartData = data.data;
        if(chartData.length)
        {
            StoreInstance.incrementVisibleeCharts();
        }
        chartData.forEach((record) => {
            let timestamp = record.timestamp;

            if (filterRangeValue.startDate === getDateDaysBefore(0)) {
                record.timestamp =
          timestamp.split('T')[1].split(':')[0] +
          ':' +
          timestamp.split('T')[1].split(':')[1];
            } else {
                record.timestamp =
          timestamp.split('T')[0].split('-')[1] +
          '-' +
          timestamp.split('T')[0].split('-')[2];
            }
        });

        StoreInstance.setO2SaturationChartData(chartData);
    });
};
export const setPulseChartDataWithFilter = (humanId, filterRangeValue) => {
    getPatientPulseReadings(humanId, filterRangeValue).then((data) => {
    // console.log("Pulse Reading==>", data.data);
        var chartData = [];
        let apiData = data.data;
        let categories = [];
        let arrayData=[];
        if(apiData.length)
        {
            StoreInstance.incrementVisibleeCharts();
        }
        apiData.forEach((record) => {
            let timestamp = record.timestamp;

            if (filterRangeValue.startDate === getDateDaysBefore(0)) {
                let time =
          timestamp.split('T')[1].split(':')[0] +
          ':' +
          timestamp.split('T')[1].split(':')[1];

                chartData.push([time, record.value]);
                arrayData.push(record);
                categories.push(time);
            } else {
                let time =
          record.timestamp.split('T')[0].split('-')[1] +
          '-' +
          record.timestamp.split('T')[0].split('-')[2];
                arrayData.push(record);

                chartData.push([time, record.value]);
                categories.push(time);
            }
        });
        // console.log("chartData===>", chartData);

        let chartDataobj = {
            data: chartData,
            arrayData:arrayData,
            categories: categories,
        };
        StoreInstance.setPulseChartData(chartDataobj);
    });
};
