import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Popover from '@material-ui/core/Popover';
import { Link, withRouter } from 'react-router-dom';
import HumanPulseChart from './charts/HumanPulseChart';
import BloodPressureChart from './charts/BloodPressureChart';
import GlucoseChart from './charts/GlucoseChart';
import O2SaturationPulseChart from './charts/O2SaturationPulseChart';
import TemperatureChart from './charts/TemperatureChart';
import { observer, inject } from 'mobx-react';
import { getNextPatientData } from '../common/api-utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import Button from '@material-ui/core/Button';
import {
    setPulseChartDataWithFilter,
    setO2SaturationChartDataWithFilter,
    setGlucoseChartDataWithFilter,
    setTemperatureChartDataWithFilter,
    setBPChartDataWithFilter,
} from '../common/apicall/chart-utils';
import { archivePatient } from '../common/apicall/patient';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const StyledTableCell = withStyles(() => ({
    head: {
        backgroundColor: '#fff',
        color: '#151515',
        fontWeight: '600',
        lineHeight: '1.2',
        padding: '5px 16px',
        fontSize: '18px',
    },
    body: {
        fontSize: 18,
        fontWeight: '500',
    },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F8F8F8',
        },
    },
}))(TableRow);

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);
class PatientDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pop_open: false,
            pop_chart_open: false,
            currentcontactItem: {},
            chartData: [],
            patientData: [],
            anchorEl: null,
            anchorElMenu: null,
            anchorBPChart: null,
            openMenu: false,
            currentSelectedPatient: undefined,
            number: 5,
            openMessageDialog: false,
            chartType: 'pulse',
            openPopup: false,
        };
    }

  checkValueAbnormal = (patientValue, min, max) => {
      if (patientValue === 'NA') {
          return false;
      }
      let value = parseInt(patientValue);
      if (value < min || value > max) {
          return true;
      } else {
          return false;
      }
  };
  handleContactClick = (event, patient) => {
      this.setState({
          anchorEl: event.currentTarget,
          pop_open: !this.state.pop_open,
          currentcontactItem: patient,
      });
  };
  handleChartColummnClick = async (event, chartType, patient) => {
      this.setState({
          anchorEl: event.currentTarget,
          chartType: chartType,
          pop_chart_open: !this.state.pop_chart_open,
          currentSelectedPatient: patient,
      });
      console.log('event.currentTarget', event.currentTarget);
  };
  handleClose = () => {
      this.setState({
          pop_open: false,
          pop_chart_open: false,
      });
  };

  handlePopupClose = () => {
      this.setState({ openPopup: false });
  };

  handleMenuClose = () => {
      this.setState({ anchorElMenu: null });
  };

  loadNextData = async () => {
      const { centralStore } = this.props;
      let key = JSON.parse(localStorage.getItem('lastevaluatedkey'));
      if (key === 'NA') {
          centralStore.setHasMore(false);
      } else {
          centralStore.setHasMore(true);
          getNextPatientData();
      }
  };

  handleMessageDialogOpen = (event, patient) => {
      const { centralStore } = this.props;

      centralStore.setSelectedPatientData(patient);
      centralStore.openMessageDialog();
      console.log('openMessageDialogValue', centralStore.openMessageDialogValue);
  };
  handleMessageDialogClose = () => {
      const { centralStore } = this.props;
      centralStore.closeMessageDialog();
  };

  handleArchiveInactive = (patient) => {
      const { centralStore } = this.props;
      this.setState({ openPopup: true });
      this.setState({ successMessage: 'Patient Archived Successfully!' });
      window.location.reload();

      let patientData = {
          patient_id: patient.patient_id,
          physician_id: patient.physician_id,
      };
      archivePatient(patientData).then((response) => {
          console.log('Delete Resp', response);
          centralStore.hideLoader();
      });
  };

  getChartType = (type) => {
      if (type === 'bp') {
          return <BloodPressureChart viewedFromTable={true} />;
      } else if (type === 'glucose') {
          return <GlucoseChart viewedFromTable={true} />;
      } else if (type === 'temp') {
          return <TemperatureChart viewedFromTable={true} />;
      } else if (type === 'pulse') {
          return <HumanPulseChart viewedFromTable={true} />;
      } else if (type === 'o2') {
          return <O2SaturationPulseChart viewedFromTable={true} />;
      }
  };

  splitFirstLastName = (name) => {
      let firstName = name ? name.split(' ')[0] : '';
      let lastName = name ? name.split(' ')[1] : '';
      return `${lastName}, ${firstName}`;
  };
  render() {
      const { centralStore } = this.props;

      let filterValue = centralStore.filterValue;
      let patientDataTable = centralStore.currentPatientTableDataValue || [];
      return (
          <Grid container className="table-grid-container">
              <Grid item xs={12}>
                  <Paper className="shadow-box">
                      <Box p={2} className="box-heading">
                          <Typography variant="h4">Patient Details</Typography>
                      </Box>
                      <TableContainer id="scrollableDiv" className="table-height">
                          <InfiniteScroll
                              dataLength={patientDataTable.length}
                              next={this.loadNextData}
                              hasMore={centralStore.currentHasMoreValue}
                              loader={
                                  <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                  >
                                      <CircularProgress className="loading-more-record" />
                    Loading More Records...
                                  </Box>
                              }
                              endMessage={
                                  <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                      marginTop={2}
                                      marginBottom={2}
                                  >
                                      <Alert severity="info">
                                          {patientDataTable.length
                                              ? 'All ' +
                        patientDataTable.length +
                        ' Records Fetched...!'
                                              : 'No Records To Display...!'}
                                      </Alert>
                                  </Box>
                              }
                              scrollableTarget="scrollableDiv"
                          >
                              <Table
                                  stickyHeader
                                  className="patient-tbl"
                                  aria-label="sticky table"
                                  size="small"
                              >
                                  <TableHead>
                                      <TableRow>
                                          <StyledTableCell>Patient Name</StyledTableCell>
                                          <StyledTableCell align="center">DOB</StyledTableCell>
                                          <StyledTableCell align="center">BP</StyledTableCell>
                                          <StyledTableCell align="center">Pulse</StyledTableCell>
                                          <StyledTableCell align="center">
                        O2
                                              <br />
                        Saturation
                                          </StyledTableCell>

                                          <StyledTableCell align="center">Glucose</StyledTableCell>

                                          <StyledTableCell align="center">
                                              <div className="data-with-sub-text">
                                                  <div>Weight</div>
                                                  <span>(LB/KG)</span>
                                              </div>
                                          </StyledTableCell>

                                          <StyledTableCell align="center">
                                              <div className="data-with-sub-text">
                                                  <div>Temp</div>
                                                  <span>(F/C)</span>
                                              </div>
                                          </StyledTableCell>
                                          <StyledTableCell align="center">Age</StyledTableCell>
                                          <StyledTableCell align="center">Phone</StyledTableCell>
                                          <StyledTableCell align="center">SMS</StyledTableCell>
                                          <StyledTableCell align="center">Provider</StyledTableCell>
                      
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      <StyledTableRow className="hint-row">
                                          <StyledTableCell>Last Name, First Name</StyledTableCell>
                                          <StyledTableCell align="center">
                        Month/Day/Year
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                        SBP(91-139)/DBP(51-98)
                                          </StyledTableCell>                      
                                          <StyledTableCell align="center">51-119</StyledTableCell>
                                          <StyledTableCell align="center">95-100</StyledTableCell>
                                          <StyledTableCell align="center">93-100</StyledTableCell>
                                          <StyledTableCell align="center">100/45</StyledTableCell>
                                          <StyledTableCell align="center">
                        96.8-99.5/36.0-37.5
                                          </StyledTableCell>
                                          <StyledTableCell align="center">Years</StyledTableCell>
                      
                                          <StyledTableCell align="center">
                        1(805) 123-4567
                                          </StyledTableCell>
                                          <StyledTableCell align="center">Message </StyledTableCell>
                                          <StyledTableCell align="center">MD/DO/NP</StyledTableCell>
                      
                                      </StyledTableRow>

                                      {patientDataTable.map((patient, index) => {
                                          return (
                                              <StyledTableRow key={index}>
                                                  <StyledTableCell>
                                                      <Link
                                                          onContextMenu={async (event) => {
                                                              event.preventDefault();
                                                              this.setState({
                                                                  anchorElMenu: event.currentTarget,
                                                                  currentSelectedPatient: patient,
                                                              });
                                                          }}
                                                          onClick={() => {
                                                              localStorage.setItem(
                                                                  'patient_id',
                                                                  patient.patient_id
                                                              );
                                                          }}
                                                          to="/patientdashboard"
                                                      >
                                                          {patient.lastName + ', ' + patient.firstName}
                                                      </Link>
                                                  </StyledTableCell>
                                                
                                                  <StyledTableCell align="center">
                                                      {patient.dob}
                                                  </StyledTableCell>
                          

                                                  <LightTooltip
                                                      title={
                                                          <React.Fragment>
                                                              <b>{` Date: ${patient.blood_pressure.time?patient.blood_pressure.time.split(' ')[0]: ''}`}</b>
                                                              <br />
                                                              <b>{` Time: ${patient.blood_pressure.time?patient.blood_pressure.time.split(' ')[1]: ''}`}</b>
                                                          </React.Fragment>
                                                      }
                                                      placement="top">
                                                      <StyledTableCell align="center">
                                                          <Box
                                                              display="flex"
                                                              alignItems="center"
                                                              justifyContent="center"
                                                          >
                                                              <Box
                                                                  className="provider-name"
                                                                  onClick={async (event) => {
                                                                      this.handleChartColummnClick(
                                                                          event,
                                                                          'bp',
                                                                          patient
                                                                      );
                                                                      setBPChartDataWithFilter(
                                                                          patient.humanId,
                                                                          filterValue
                                                                      );
                                                                  }}
                                                                  size={{ height: 210 }}
                                                                  color={
                                                                      this.checkValueAbnormal(
                                                                          patient.blood_pressure.SBP,
                                                                          91,
                                                                          139
                                                                      ) ||
                                    this.checkValueAbnormal(
                                        patient.blood_pressure.DBP,
                                        51,
                                        98
                                    )
                                                                          ? 'red'
                                                                          : 'black'
                                                                  }
                                                              >
                                                                  {patient.blood_pressure
                                                                      ? `${patient.blood_pressure.SBP}/${patient.blood_pressure.DBP}`
                                                                      : 'Invalid Data From Api'}
                                                              </Box>
                                                          </Box>
                                                      </StyledTableCell>
                                                  </LightTooltip>

                                                  <LightTooltip
                                                      title={
                                                          <React.Fragment>
                                                              <b>{` Date: ${patient.blood_pressure.time?patient.blood_pressure.time.split(' ')[0]: ''}`}</b>
                                                              <br />
                                                              <b>{` Time: ${patient.blood_pressure.time?patient.blood_pressure.time.split(' ')[1]: ''}`}</b>
                                                          </React.Fragment>
                                                      }
                                                      placement="top">
                                                      <StyledTableCell align="center">
                                                          <Box
                                                              display="flex"
                                                              alignItems="center"
                                                              justifyContent="center"
                                                          >
                                                              <Box
                                                                  className="provider-name"
                                                                  onClick={async (event) => {
                                                                      this.handleChartColummnClick(
                                                                          event,
                                                                          'pulse',
                                                                          patient
                                                                      );
                                                                      setPulseChartDataWithFilter(
                                                                          patient.humanId,
                                                                          filterValue
                                                                      );
                                                                  }}
                                                                  color={
                                                                      this.checkValueAbnormal(
                                                                          patient.pulse.value,
                                                                          51,
                                                                          119
                                                                      )
                                                                          ? 'red'
                                                                          : 'black'
                                                                  }
                                                              >
                                                                  {patient.pulse
                                                                      ? patient.pulse.value
                                                                      : 'Invalid Data From Api'}
                                                              </Box>
                                                          </Box>
                                                      </StyledTableCell>
                                                  </LightTooltip>

                                                  <LightTooltip
                                                      title={
                                                          <React.Fragment>
                                                              <b>{` Date: ${patient.blood_pressure.time?patient.blood_pressure.time.split(' ')[0]: ''}`}</b>
                                                              <br />
                                                              <b>{` Time: ${patient.blood_pressure.time?patient.blood_pressure.time.split(' ')[1]: ''}`}</b>
                                                          </React.Fragment>
                                                      }
                                                      placement="top">
                                                      <StyledTableCell align="center">
                                                          <Box
                                                              display="flex"
                                                              alignItems="center"
                                                              justifyContent="center"
                                                          >
                                                              <Box
                                                                  className="provider-name"
                                                                  onClick={async (event) => {
                                                                      this.handleChartColummnClick(
                                                                          event,
                                                                          'o2',
                                                                          patient
                                                                      );
                                                                      setO2SaturationChartDataWithFilter(
                                                                          patient.humanId,
                                                                          filterValue
                                                                      );
                                                                  }}

                                                                  color={
                                                                      this.checkValueAbnormal(
                                                                          patient.o2_saturation.value,
                                                                          95,
                                                                          100
                                                                      )
                                                                          ? 'red'
                                                                          : 'black'
                                                                  }

                                                              >
                                                                  {patient.o2_saturation
                                                                      ? patient.o2_saturation.value
                                                                      : 'Invalid Data From Api'}
                                                              </Box>
                                                          </Box>
                                                      </StyledTableCell>
                                                  </LightTooltip>

                                                  <StyledTableCell align="center">
                                                      <Box
                                                          display="flex"
                                                          alignItems="center"
                                                          justifyContent="center"
                                                      >
                                                          <Box
                                                              className="provider-name"
                                                              onClick={async (event) => {
                                                                  this.handleChartColummnClick(
                                                                      event,
                                                                      'glucose',
                                                                      patient
                                                                  );
                                                                  setGlucoseChartDataWithFilter(
                                                                      patient.humanId,
                                                                      filterValue
                                                                  );
                                                              }}
                                                              color={
                                                                  this.checkValueAbnormal(
                                                                      patient.glucose.value,
                                                                      93,
                                                                      100
                                                                  )
                                                                      ? 'red'
                                                                      : 'black'
                                                              }
                                                          >
                                                              {patient.glucose
                                                                  ? patient.glucose.value
                                                                  : 'Invalid Data From Api'}
                                                          </Box>
                                                      </Box>
                                                  </StyledTableCell>
                                                  <StyledTableCell align="center">
                                                      {patient.weight.value_lb && patient.weight.value
                                                          ? `${patient.weight.value_lb}/${patient.weight.value}`
                                                          : 'Invalid Data From Api'}
                                                  </StyledTableCell>
                                                  <StyledTableCell align="center">
                                                      <Box
                                                          display="flex"
                                                          alignItems="center"
                                                          justifyContent="center"
                                                      >
                                                          <Box
                                                              className="provider-name"
                                                              onClick={async (event) => {
                                                                  this.handleChartColummnClick(
                                                                      event,
                                                                      'temp',
                                                                      patient
                                                                  );
                                                                  setTemperatureChartDataWithFilter(
                                                                      patient.humanId,
                                                                      filterValue
                                                                  );
                                                              }}
                                                              color={
                                                                  this.checkValueAbnormal(
                                                                      patient.temperature.value,
                                                                      36,
                                                                      37
                                                                  ) ||
                                    this.checkValueAbnormal(
                                        patient.temperature.valueF,
                                        96,
                                        99
                                    )
                                                                      ? 'red'
                                                                      : 'black'
                                                              }
                                                          >
                                                              {patient.temperature
                                                                  ? `${patient.temperature.valueF || 'NA'}/${patient.temperature.value
                                                                  }`
                                                                  : 'Invalid Data From Api'}
                                                          </Box>
                                                      </Box>
                                                  </StyledTableCell>
                                                  <StyledTableCell align="center">
                                                      {patient.age}
                                                  </StyledTableCell>
                         
                                                  <StyledTableCell align="center">
                                                      {patient.phone}
                                                  </StyledTableCell>
                                                  <StyledTableCell align="center">
                                                      <Button
                                                          onClick={(event) => {
                                                              this.handleMessageDialogOpen(event, patient);
                                                          }}
                                                          className="icon-sms"
                                                      >
                                                          <MailOutlineOutlinedIcon />
                                                      </Button>{' '}
                                                  </StyledTableCell>
                                                  <StyledTableCell align="center">
                                                      <Box display="flex" alignItems="center">
                                                          <Box
                                                              component="span"
                                                              display="flex"
                                                              alignItems="center"
                                                              mr={1}
                                                              className="contect-icon"
                                                              onClick={(event) => {
                                                                  this.handleContactClick(
                                                                      event,
                                                                      patient.provider
                                                                  );
                                                              }}
                                                          >
                                                              <i className="icon-phone-book"></i>
                                                          </Box>
                                                          <Box
                                                              className="provider-name"
                                                              onClick={(event) => {
                                                                  this.handleContactClick(
                                                                      event,
                                                                      patient.provider
                                                                  );
                                                              }}
                                                          >
                                                              {this.splitFirstLastName(patient.provider.name)}
                                                          </Box>
                                                      </Box>
                                                  </StyledTableCell>

                                              </StyledTableRow>
                                          );
                                      })}
                                      <Menu
                                          id="simple-menu"
                                          anchorEl={this.state.anchorElMenu}
                                          keepMounted
                                          open={Boolean(this.state.anchorElMenu)}
                                          onClose={this.handleMenuClose}
                                      >
                                          <MenuItem
                                              onClick={() => {
                                                  this.handleMenuClose();
                                                  localStorage.setItem(
                                                      'patient_id',
                                                      this.state.currentSelectedPatient.patient_id
                                                  );
                                                  this.props.history.push('/patientdashboard');
                                              }}
                                          >
                        View Patient Dashboard
                                          </MenuItem>
                                          <MenuItem
                                              onClick={async () => {
                                                  this.handleMenuClose();
                                                  centralStore.showAddEditDialog();
                                                  console.log(
                                                      'currentSelectedPatient',
                                                      this.state.currentSelectedPatient
                                                  );
                                                  centralStore.setPatientDataForEdit(
                                                      this.state.currentSelectedPatient
                                                  );
                                              }}
                                          >
                        Edit Patient Details
                                          </MenuItem>
                                      </Menu>

                                      {/* //////////////// ====================POPUPS==========////////////// */}
                                      <Popover
                                          open={this.state.pop_open}
                                          anchorEl={this.state.anchorEl}
                                          onClose={this.handleClose}
                                          className="contact-popover-outer"
                                          anchorOrigin={{
                                              vertical: 'top',
                                              horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                          }}
                                      >
                                          <Box p={1} className="contact-popover">
                                              <Box>
                                                  <label>Phone:</label>
                                                  {this.state.currentcontactItem['custom:phone'] ||
                            ' (Not provided yet)'}
                                              </Box>
                                              <Box>
                                                  <label>Email:</label>
                                                  {this.state.currentcontactItem.email}
                                              </Box>
                                          </Box>
                                      </Popover>

                                      <Popover
                                          open={this.state.pop_chart_open}
                                          anchorEl={this.state.anchorEl}
                                          onClose={this.handleClose}
                                          className="contact-popover-outer"
                                          anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'center',
                                          }}
                                      >
                                          <Box className="chart-popover">
                                              <Box className="chart-header" p={2}>
                          Chart for{' '}
                                                  {this.state.currentSelectedPatient
                                                      ? this.state.currentSelectedPatient.lastName +
                            ' ' +
                            this.state.currentSelectedPatient.firstName
                                                      : ''}
                                              </Box>
                                              <Box className="chart-box" px={2} pb={2}>
                                                  {this.state.pop_chart_open
                                                      ? this.getChartType(this.state.chartType)
                                                      : null}
                                              </Box>
                                          </Box>
                                      </Popover>
                                  </TableBody>
                              </Table>
                          </InfiniteScroll>
                      </TableContainer>
                  </Paper>
              </Grid>
          </Grid>
      );
  }
}

export default withRouter(inject('centralStore')(observer(PatientDetails)));
