import React, { useEffect, useCallback,useState } from 'react';
//import clsx from "clsx";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
//import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
//import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import MailIcon from '@material-ui/icons/Mail';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Auth, Storage } from 'aws-amplify';
import { observer, inject } from 'mobx-react';
import userImg from '../assets/images/avtar.png';
import {Link} from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';
import logo from '../assets/images/MVS-Logo.png';
import { NavLink } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
  
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
    // marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
  
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));


function SideNavigation({ window, centralStore, currentUserInfo, history }) {
    // const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    //const [open, setOpen] = useState(true);
    const [imageUrl, setImageUrl] = useState(undefined);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

   
    const getUrl = useCallback(() => {
        Storage.get(currentUserInfo['custom:profileImage'])
            .then((result) => {
                console.log('Get Respnse===>', result);
                if (currentUserInfo['custom:profileImage'] !== 'NA') {
                    setImageUrl(result);
                }
            })
            .catch((err) => {
                console.log('Get ERROR==>', err);
            });
    }, [currentUserInfo]);
    useEffect(() => {
        getUrl();
    }, [getUrl]);
    let firstName = currentUserInfo.name
        ? currentUserInfo.name.split(' ')[0]
        : '';
    let lastName = currentUserInfo.name ? currentUserInfo.name.split(' ')[1] : '';


    // For dropdown
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerDesktopToggle=()=>{
        document.body.classList.toggle('sidebar-collapse');
    };


    const drawer = (
        <div>
            <div className={classes.drawerHeader}>
                <div className="sidebar-logo">
                    <img src={logo} alt="" className="img-fluid" />
                </div>
            </div>
            {currentUserInfo['custom:role'] !==  'admin' ? (
                <List>
                    <ListItem button>
                        <NavLink to="/home" className="nav-link" activeClassName="active">
                            <ListItemIcon className="menu-icon">
                                <i className="icon-home"></i>
                            </ListItemIcon>
                            <ListItemText primary="Home"/>
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink to="/userprofile" className="nav-link" activeClassName="active">
                            <ListItemIcon className="menu-icon">
                                <i className="icon-user"></i>
                            </ListItemIcon>
                            <ListItemText primary="My Profile"/>
                        </NavLink>
                    </ListItem>
                </List>
            ) : (
                <List>
                    <ListItem button>
                        <NavLink to="/home" className="nav-link" activeClassName="active">
                            <ListItemIcon className="menu-icon">
                                <i className="icon-home"></i>
                            </ListItemIcon>
                            <ListItemText primary="Home"/>
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink to="/userprofile" className="nav-link" activeClassName="active">
                            <ListItemIcon className="menu-icon">
                                <i className="icon-user"></i>
                            </ListItemIcon>
                            <ListItemText primary="My Profile" />
                        </NavLink>
                    </ListItem>
                    
                    <ListItem button>
                        <NavLink to="/archivedlists" className="nav-link" activeClassName="active">
                            <ListItemText primary="Archived List"/>
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink to="/clientlist" className="nav-link" activeClassName="active">
                            <ListItemText primary="Client's List" />
                        </NavLink>
                    </ListItem>
                </List>
            )}
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root + ' sidebar-outer'}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar + ' header'}>
                <Toolbar className="header-inner">
                    <div>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            className="desktop-menu-icon"
                            edge="start"
                            onClick={handleDrawerDesktopToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Link to="/home">         
                            <IconButton
                                className={classes.menuButton}>
                                <HomeIcon fontSize="small" />
                            </IconButton>
                        </Link>
          
                    </div>
                    <Box className="user-section">
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <Box display="flex" alignItems="center">
                                <Avatar
                                    alt=""
                                    src={imageUrl || userImg}
                                    className={classes.large}
                                />
                                <Box ml={2} className="user-info">
                                    <Box component="span" className="welcome">
                    Welcome
                                    </Box>
                                    <Typography variant="h5" className="user-name">
                                        {firstName} {lastName}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" component="span" ml={1}>
                                    <ExpandMoreOutlinedIcon />
                                </Box>
                            </Box>
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    history.push('/userprofile');
                                }}
                            >
                My Profile
                            </MenuItem>
                            <MenuItem
                                onClick={async () => {
                                    await Auth.signOut();
                                    centralStore.setUserAuthenticated(false);
                                    window.location.replace('/');
                                   
                                }}
                            >
                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        className="sidebar"
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        className="sidebar" 
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
}

SideNavigation.propTypes = {
    /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
    window: PropTypes.func,
};

export default withRouter(inject('centralStore')(observer(SideNavigation)));
