/* eslint-disable indent */
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {checkAllFieldsValid} from '../../common/util';
import { setPhysicianTable } from '../../common/api-utils';
import { when } from 'mobx';
import { addPhysician, updatePhysician } from '../../common/apicall/physician';


export class AddEditPhysicianDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            physicianData: [],
            firstName: '',
            lastName: '',
            speciality:'',
            email: '',
            // emailId:'',
            password:'',
            phone: '',
            address:'',
            age:'',
            role:'',
            addPhysicianError: false,
            addPhysicianErrorMessage: ' ',
        };
    }
  

  componentDidMount = () => {
      const { centralStore } = this.props;
      when(
          () => centralStore.currentPhysicianDataForEditValue,
          () => {
              this.setState(centralStore.currentPhysicianDataForEditValue);
          }
      );
  };

  onAddPhysicianChange = (event) => {
      event.preventDefault();
      this.setState({
          [event.target.name]: event.target.value,
          addPhysicianErrorMessage: ' ',
      });
  };
 
 
onPhysicianSubmit = async () => {
    const { centralStore } = this.props;
    let physiciandata = {};
    physiciandata = this.state;
    console.log(this.state);
    if (checkAllFieldsValid(physiciandata) === true) {
        centralStore.showLoader();
        centralStore.setSelectedPhysicianData(physiciandata);
        addPhysician(physiciandata)
            .then(async (response) => {
                console.log('ADD Response', response);
                centralStore.setPhysicianDataForEdit(undefined);
                centralStore.hideAddEditDialog();
                centralStore.setHasMore(true);
                await setPhysicianTable();
            })
            .catch((error) => {
                error.then((errorressponse) => {
                    this.setState({
                        addPhysicianErrorMessage: errorressponse.message,
                    });
                });
            });
    }
    else {
        this.setState({ addPatientError: true });
    }

    if (physiciandata.emailId) {
        updatePhysician(physiciandata).then(async (response) => {
            console.log('Update Response', response);
            centralStore.setPhysicianDataForEdit(undefined);
            centralStore.hideAddEditDialog();
            await setPhysicianTable();
        })
        .catch((error) => {
            error.then((errorressponse) => {
                this.setState({
                    addPhysicianErrorMessage: errorressponse.message,
                });
            });
        });
    }else {this.setState({ addPatientError: true });
 }
}

 render() {
        const { centralStore } = this.props;
        return (
            <div>
                <Dialog
                    open={centralStore.currentopenDialogValue}
                    onClose={() => {
                        centralStore.setPhysicianDataForEdit(undefined);
                        centralStore.hideAddEditDialog();
                        centralStore.hideLoader();
                    }}
                    className="dialog-box"
                >
                    <DialogTitle className="modal-header">
                        {centralStore.currentPhysicianDataForEditValue
                            ? 'Edit Client'
                            : 'Add Client'}
                    </DialogTitle>
                    {centralStore.currentPhysicianDataForEditValue ? 
                        (
                            <DialogContent className="dialog-content">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={
                                                !this.state.firstName.length && this.state.addPhysicianError
                                            }
                                            helperText={
                                                !this.state.firstName.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="firstName"
                                            value={this.state.firstName}
                                            onChange={this.onAddPhysicianChange}
                                            label="First Name"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={
                                                !this.state.lastName.length && this.state.addPhysicianError
                                            }
                                            helperText={
                                                !this.state.lastName.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="lastName"
                                            value={this.state.lastName}
                                            onChange={this.onAddPhysicianChange}
                                            label="Last Name"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={!this.state.email.length && this.state.addPhysicianError}
                                            helperText={
                                                !this.state.email.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            required
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.onAddPhysicianChange}
                                            label="Email Id"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                        {this.state.addPhysicianErrorMessage &&
                this.state.addPhysicianErrorMessage.length > 1 &&
                this.state.addPhysicianErrorMessage.includes('Email') ? (
                                                <p style={{ color: 'red' }}>
                                                    {this.state.addPhysicianErrorMessage}
                                                </p>
                                            ) : null}
                                    </Grid>
              
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={!this.state.password.length && this.state.addPhysicianError}
                                            helperText={
                                                !this.state.password.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="password"
                                            type="password"
                                            value={this.state.password}
                                            onChange={this.onAddPhysicianChange}
                                            label="password"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                        {this.state.addPhysicianErrorMessage &&
                this.state.addPhysicianErrorMessage.length > 1 &&
                this.state.addPhysicianErrorMessage.includes('password') ? (
                                                <p style={{ color: 'red' }}>
                                                    {this.state.addPhysicianErrorMessage}
                                                </p>
                                            ) : null}
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={!this.state.age.length && this.state.addPhysicianError}
                                            helperText={
                                                !this.state.age.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="age"
                                            value={this.state.age}
                                            onChange={this.onAddPhysicianChange}
                                            label="Age"
                                            variant="outlined"
                                            fullWidth={true}
                                            type="number"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={!this.state.phone.length && this.state.addPhysicianError}
                                            helperText={
                                                !this.state.phone.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="phone"
                                            value={this.state.phone}
                                            onChange={this.onAddPhysicianChange}
                                            label="Phone"
                                            type="tel"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                        {this.state.addPhysicianErrorMessage &&
                this.state.addPhysicianErrorMessage.length > 1 &&
                this.state.addPhysicianErrorMessage.includes('Phone') ? (
                                                <p style={{ color: 'red' }}>
                                                    {this.state.addPhysicianErrorMessage}
                                                </p>
                                            ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={
                                                !this.state.speciality.length &&
                    this.state.addPhysicianError
                                            }
                                            helperText={
                                                !this.state.speciality.length &&
                    this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="speciality"
                                            value={this.state.speciality}
                                            onChange={this.onAddPhysicianChange}
                                            label="Speciality"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={
                                                !this.state.address.length && this.state.addPhysicianError
                                            }
                                            helperText={
                                                !this.state.address.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="address"
                                            value={this.state.address}
                                            onChange={this.onAddPhysicianChange}
                                            label="address"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={
                                                !this.state.role.length && this.state.addPhysicianError
                                            }
                                            helperText={
                                                !this.state.role.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="role"
                                            value={this.state.role}
                                            onChange={this.onAddPhysicianChange}
                                            label="Role"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                   
                        ) : (
                            <DialogContent className="dialog-content">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={
                                                !this.state.firstName.length && this.state.addPhysicianError
                                            }
                                            helperText={
                                                !this.state.firstName.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="firstName"
                                            value={this.state.firstName}
                                            onChange={this.onAddPhysicianChange}
                                            label="First Name"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={
                                                !this.state.lastName.length && this.state.addPhysicianError
                                            }
                                            helperText={
                                                !this.state.lastName.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="lastName"
                                            value={this.state.lastName}
                                            onChange={this.onAddPhysicianChange}
                                            label="Last Name"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={!this.state.email.length && this.state.addPhysicianError}
                                            helperText={
                                                !this.state.email.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            required
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.onAddPhysicianChange}
                                            label="Email Id"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                        {this.state.addPhysicianErrorMessage &&
                this.state.addPhysicianErrorMessage.length > 1 &&
                this.state.addPhysicianErrorMessage.includes('Email') ? (
                                                <p style={{ color: 'red' }}>
                                                    {this.state.addPhysicianErrorMessage}
                                                </p>
                                            ) : null}
                                    </Grid>
              
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={!this.state.password.length && this.state.addPhysicianError}
                                            helperText={
                                                !this.state.password.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            required
                                            name="password"
                                            type="password"
                                            value={this.state.password}
                                            onChange={this.onAddPhysicianChange}
                                            label="password"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                        {this.state.addPhysicianErrorMessage &&
                this.state.addPhysicianErrorMessage.length > 1 &&
                this.state.addPhysicianErrorMessage.includes('password') ? (
                                                <p style={{ color: 'red' }}>
                                                    {this.state.addPhysicianErrorMessage}
                                                </p>
                                            ) : null}
                                    </Grid>
                         
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={!this.state.age.length && this.state.addPhysicianError}
                                            helperText={
                                                !this.state.age.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="age"
                                            value={this.state.age}
                                            onChange={this.onAddPhysicianChange}
                                            label="Age"
                                            variant="outlined"
                                            fullWidth={true}
                                            type="number"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={!this.state.phone.length && this.state.addPhysicianError}
                                            helperText={
                                                !this.state.phone.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="phone"
                                            value={this.state.phone}
                                            onChange={this.onAddPhysicianChange}
                                            label="Phone"
                                            type="tel"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                        {this.state.addPhysicianErrorMessage &&
                this.state.addPhysicianErrorMessage.length > 1 &&
                this.state.addPhysicianErrorMessage.includes('Phone') ? (
                                                <p style={{ color: 'red' }}>
                                                    {this.state.addPhysicianErrorMessage}
                                                </p>
                                            ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={
                                                !this.state.speciality.length &&
                    this.state.addPhysicianError
                                            }
                                            helperText={
                                                !this.state.speciality.length &&
                    this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="speciality"
                                            value={this.state.speciality}
                                            onChange={this.onAddPhysicianChange}
                                            label="Speciality"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={
                                                !this.state.address.length && this.state.addPhysicianError
                                            }
                                            helperText={
                                                !this.state.address.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="address"
                                            value={this.state.address}
                                            onChange={this.onAddPhysicianChange}
                                            label="address"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={
                                                !this.state.role.length && this.state.addPhysicianError
                                            }
                                            helperText={
                                                !this.state.role.length && this.state.addPhysicianError
                                                    ? 'Field Cannot be Empty'
                                                    : ''
                                            }
                                            name="role"
                                            value={this.state.role}
                                            onChange={this.onAddPhysicianChange}
                                            label="Role"
                                            variant="outlined"
                                            fullWidth={true}
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                                
                            </DialogContent>
                  
                        )}
                     <DialogActions className="modal-footer">
                     <button
                         onClick={() => {
                             centralStore.hideAddEditDialog();
                             centralStore.setPhysicianDataForEdit(undefined);
                             centralStore.hideLoader();
                         }}
                         className="btn btn-secondary"
                     >
           Close
                     </button>
                     <button onClick={this.onPhysicianSubmit} className="btn btn-primary">
           Submit
                     </button>
                 </DialogActions>
              </Dialog>
            </div>
        );
    }
}

export default inject('centralStore')(observer(AddEditPhysicianDialog));
