import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { observer, inject } from 'mobx-react';
import Alert from '@material-ui/lab/Alert/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import {
    sendMessageToPatient,
    broadcastMessageToPatients,
} from '../../common/apicall/patient';
import { toJS } from 'mobx';
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
class SmsEmaiPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabValue: 0,
            emailBody: '',
            phoneMessage: '',
            emailSubject: '',
            successMessage: '',
        };
    }

  handleMessageDialogOpen = (event) => {
      const { centralStore } = this.props;
      centralStore.openMessageDialog();
  };
  handleMessageDialogClose = () => {
      const { centralStore } = this.props;
      centralStore.closeMessageDialog();
  };

  handleMessageSend = () => {
      let flag = this.state.tabValue === 0 ? 'email' : 'phone';
      const { centralStore } = this.props;
      const { selectedPatientDataValue } = centralStore;
      let dataBody = {};

      if (flag === 'email') {
          dataBody = {
              flag: flag,
              receiver: selectedPatientDataValue.email,
              message: this.state.emailSubject,
              body_txt: this.state.emailBody,
          };
          if (this.state.emailSubject.length && this.state.emailBody.length) {
              if (selectedPatientDataValue && selectedPatientDataValue.firstName) {
                  sendMessageToPatient(dataBody).then((response) => {
                      console.log('response for Message==>', response);
                      this.setState({ successMessage: 'Messsage sent successfully' });
                  });
              } else {
                  broadcastMessageToPatients({
                      flag: flag,
                      message: this.state.emailSubject,
                      body_txt: this.state.emailBody,
                  }).then((response) => {
                      console.log('response for Message==>', response);
                      this.setState({ successMessage: 'Messsage sent successfully' });
                  });
              }

              setTimeout(() => {
                  this.handleMessageDialogClose();
                  this.setState({ successMessage: '' });
              }, 3000);
          }
      } else {
          console.log('In else==>');
          dataBody = {
              flag: flag,
              receiver: selectedPatientDataValue.phone,
              message: this.state.phoneMessage,
          };
          if (this.state.phoneMessage.length) {
              if (selectedPatientDataValue && selectedPatientDataValue.firstName) {
                  sendMessageToPatient(dataBody).then((response) => {
                      console.log('response for Message==>', response);
                      this.setState({ successMessage: 'Messsage sent successfully' });
                  });
              } else {
                  broadcastMessageToPatients({
                      flag: flag,
                      message: this.state.emailSubject,
                  }).then((response) => {
                      console.log('response for Message==>', response);
                      this.setState({ successMessage: 'Messsage sent successfully' });
                  });
              }
              setTimeout(() => {
                  this.handleMessageDialogClose();
                  this.setState({ successMessage: '' });
              }, 3000);
          }
      }
  };
  a11yProps = (index) => {
      return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
      };
  };
  handleTabChange = (event, newValue) => {
      console.log('INDEX', newValue);
      this.setState({ tabValue: newValue });
  };

  handleMessageChange = (event) => {
      this.setState({ [event.target.name]: event.target.value });
  };

  render() {
      const { centralStore } = this.props;
      const { selectedPatientDataValue } = centralStore;
      console.log('openMessageDialogValue', toJS(selectedPatientDataValue));

      return (
          <div>
              {' '}
              <Dialog
                  open={centralStore.openMessageDialogValue}
                  onClose={this.handleMessageDialogClose}
                  aria-labelledby="form-dialog-title"
                  maxWidth="sm"
                  fullWidth={true}
              >
                  <DialogContent className="sms-tabs">
                      <Paper>
                          <AppBar position="static">
                              <h4>
                                  {selectedPatientDataValue &&
                  selectedPatientDataValue.firstName
                                      ? selectedPatientDataValue.firstName +
                      ' ' +
                      selectedPatientDataValue.lastName
                                      : 'Broadcast To all Patients'}
                              </h4>
                              <Tabs
                                  value={this.state.tabValue}
                                  onChange={this.handleTabChange}
                                  aria-label="simple tabs example"
                              >
                                  <Tab label="Send Email" {...this.a11yProps(0)} />

                                  <Tab
                                      disabled={
                                          selectedPatientDataValue &&
                      selectedPatientDataValue.phone === 'NA'
                                      }
                                      label="Send SMS"
                                      {...this.a11yProps(1)}
                                  />
                              </Tabs>
                          </AppBar>
                          <TabPanel value={this.state.tabValue} index={0}>
                Email{' '}
                              {selectedPatientDataValue &&
                selectedPatientDataValue.phone === 'NA' ? (
                                      <p style={{ color: 'red' }}>
                    Phone number not avaliable So cannot send SMS
                                      </p>
                                  ) : null}
                              <TextField
                                  autoFocus
                                  onChange={this.handleMessageChange}
                                  name="emailSubject"
                                  margin="dense"
                                  id="name"
                                  label="Enter Subject "
                                  error={!this.state.emailSubject.length}
                                  helperText={
                                      !this.state.emailSubject.length
                                          ? 'Field Cannot be Empty'
                                          : ''
                                  }
                                  type="text"
                                  fullWidth
                                  multiline
                              />
                              <TextField
                                  autoFocus
                                  onChange={this.handleMessageChange}
                                  name="emailBody"
                                  margin="dense"
                                  id="name"
                                  label="Enter Message to send"
                                  error={!this.state.emailBody.length}
                                  helperText={
                                      !this.state.emailBody.length ? 'Field Cannot be Empty' : ''
                                  }
                                  type="text"
                                  fullWidth
                                  multiline
                              />
                          </TabPanel>
                          <TabPanel value={this.state.tabValue} index={1}>
                SMS{' '}
                              <TextField
                                  autoFocus
                                  onChange={this.handleMessageChange}
                                  name="phoneMessage"
                                  margin="dense"
                                  id="name"
                                  label="Enter Message to Send"
                                  error={!this.state.phoneMessage.length}
                                  helperText={
                                      !this.state.phoneMessage.length
                                          ? 'Field Cannot be Empty'
                                          : ''
                                  }
                                  type="text"
                                  fullWidth
                                  multiline
                              />
                          </TabPanel>
                          {this.state.successMessage.length ? (
                              <Alert severity="success">{this.state.successMessage}</Alert>
                          ) : null}
                          <Box textAlign="right" pt={1} pb={3} px={3}>
                              <button
                                  className="btn btn-primary"
                                  onClick={this.handleMessageSend}
                                  color="primary"
                              >
                  Send
                              </button>
                          </Box>
                      </Paper>
                  </DialogContent>
              </Dialog>
          </div>
      );
  }
}

export default inject('centralStore')(observer(SmsEmaiPopup));
