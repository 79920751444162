import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { observer, inject } from 'mobx-react';
import { setPhysicianTable } from '../../common/api-utils';
import { getFormattedDate } from '../../common/util';
import ClientListsDetails from '../ClientListsDetails';
import AddEditPhysicianDialog from './AddEditPhysicianDialog';

document.body.classList.remove('bodyStyle');

class ClientLists extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            physicanData: [],
            openDialog: false,
            firstName: '',
            lastName: '',
            phone: '',
            age: '',
            email: '',
            speciality:'',
            address:'',
            timestamp:'',
            addPhysicianError: false,
        };
    }

  handleDialogClickOpen = () => {
      this.setState({
          openDialog: true,
      });
  };

  handleDialogClose = () => {
      this.setState({
          openDialog: false,
          firstName: '',
          lastName: '',
          phone: '',
          age: '',
          email: '',
          speciality:'',
          address:'',
          timestamp:'',
          addPhysicianError: false,
      });
  };

  componentDidMount = async () => {
      const { centralStore } = this.props;
      localStorage.setItem('lastphysicianevaluatedkey', JSON.stringify({}));
      await setPhysicianTable();
      centralStore.setNumberOfVisibleCharts(0);
  };

  render() {
      const { centralStore } = this.props;
      let firstName = this.props.currentUserInfo.name
          ? this.props.currentUserInfo.name.split(' ')[0]
          : '';
      let lastName = this.props.currentUserInfo.name
          ? this.props.currentUserInfo.name.split(' ')[1]
          : '';
      return (
          <div className="content">
              <Box
                  display="flex"
                  justifyContent="space-between"
                  className="page-header"
              >
                  {/* <Typography variant="h3" className="page-name">
                  Client Lists
                  </Typography> */}
              </Box>
              <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="physician-and-doc-info"
              >
                  <Box className="info-section">
                      <label>Doctor Name</label>
                      <Typography variant="h5" className="doc-patient-name">
                          {firstName} {lastName}
                      </Typography>
                  </Box>
                  <Box textAlign="right" className="info-section">
                      <Box component="p">
                          <label>Date : </label> {getFormattedDate(new Date(), true)}
                      </Box>
                  </Box>
              </Box>
              <Box textAlign="right" mb={2}>
                  <button
                      style={{ display: 'none' }}
                      className="btn btn-primary"
                      onClick={() => {
                          centralStore.setSelectedPhysicianData({
                              physician_id: '',
                              firstName: '',
                              lastName: '',
                              phone: '',
                              age: '',
                              email: '',
                              speciality:'',
                              address:'',
                          });
                          centralStore.openMessageDialog();
                      }}
                  >
            Broadcast Message
                  </button>{' '}
                  <button
                      className="btn btn-primary"
                      onClick={() => {
                          centralStore.showAddEditDialog();
                      }}
                  >
            Add Client
                  </button>
              </Box>
              <ClientListsDetails/>
              {centralStore.currentopenDialogValue ? (
                  <AddEditPhysicianDialog {...this.props} />
              ) : null}
          </div>
      );
  }
}
export default  inject('centralStore')(observer(ClientLists));
