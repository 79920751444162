import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import SideNavigation from '../components/SideNavigation';
import { CssBaseline, Snackbar } from '@material-ui/core';
import Footer from '../components/Footer';
import clsx from 'clsx';
import ReadingPopup from '../components/pages/ReadingPopup';
import ChatApp from '../components/pages/ChatApp';
import { observer, inject } from 'mobx-react';
import MuiAlert from '@material-ui/lab/Alert';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { styles } from './util';
import { Auth } from 'aws-amplify';
import SmsEmaiPopup from '../components/pages/SmsEmaiPopup';
import AlarmPopup from '../components/pages/AlarmPopup1';
const withSideBar = (WrappedComponent) => {
    // ...and returns another component...
    class ToWrap extends React.PureComponent {
        constructor(props) {
            super(props);
            this.state = { currentUserInfo: {} };
        }

    componentDidMount = async () => {
        const { centralStore } = this.props;
        centralStore.setNavigationDrawerOpen(true);
        centralStore.hideAuthLoader();
        await Auth.currentAuthenticatedUser({ bypassCache: true })
            .then((currentUserInfo) => {
                console.log('currentUserInfo', currentUserInfo);
                this.setState({ currentUserInfo: currentUserInfo.attributes });
                localStorage.setItem(
                    'sessionData',
                    JSON.stringify(currentUserInfo.signInUserSession)
                );
            })
            .catch((error) => {
                localStorage.clear();
                window.location.replace('/');
            });
        // ... that takes care of the subscription...
    };

    Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };

    render() {
        const { classes, centralStore } = this.props;
        return (
            <div className={classes.root}>
                <Backdrop
                    className={classes.backdrop}
                    open={centralStore.currentloaderValue}
                    onClick={() => {}}
                >
                    <CircularProgress className={classes.backdrop} color="inherit" />
                </Backdrop>
                <CssBaseline />
                <SideNavigation {...this.props} {...this.state} />
                <main
                    className={
                        clsx(classes.content, {
                            [classes.contentShift]:
                  centralStore.currentOpenNavigationDrawerValue,
                        }) + ' main-wrapper'
                    }
                >
                    <div className="main-content">
                        {centralStore.openScheduleDialogValue === true ? (
                            <ChatApp centralStore={centralStore} />
                        ) : null}
                        {centralStore.currentShowReadingPopup === true ? (
                            <ReadingPopup centralStore={centralStore} />
                        ) : null}
                        {centralStore.openMessageDialogValue === true ? (
                            <SmsEmaiPopup centralStore={centralStore} />
                        ) : null}
                        {centralStore.currentShowAlarmPopup === true ? (
                            <AlarmPopup centralStore={centralStore} />
                        ) : null}
                        <Snackbar
                            open={centralStore.hasErrorOccured}
                            autoHideDuration={5000}
                            onClose={() => {
                                centralStore.errorHasOccured(false);
                                if (
                                    centralStore.currentErrorMessage.includes('Forbidden') ||
                    centralStore.currentErrorMessage === null
                                ) {
                                    alert(
                                        'Looks like your Session has expired. Please Click OK to reload..!'
                                    );
                                    centralStore.setUserAuthenticated(false);
                                    window.location.replace('/');
                                }
                            }}
                        >
                            <this.Alert
                                onClose={() => {
                                    centralStore.errorHasOccured(false);
                                }}
                                severity="error"
                            >
                                {centralStore.currentErrorMessage}
                            </this.Alert>
                        </Snackbar>

                        <div className={classes.drawerHeader} />
                        <WrappedComponent {...this.props} {...this.state} />
                    </div>
                    <Footer />
                </main>
            </div>
        );
    }
    }
    return withStyles(styles)(inject('centralStore')(observer(ToWrap)));
};

export default withSideBar;
