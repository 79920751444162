import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { addPatient, updatePatient } from '../../common/apicall/patient';
import {
    checkAllFieldsValid,
    getFormattedDate,
    getDateDaysBefore,
    calculateAge,
} from '../../common/util';
import { setPatientTableWithFilter } from '../../common/api-utils';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { when } from 'mobx';

export class AddEditPatientDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patientData: [],
            firstName: '',
            lastName: '',
            relativename: '',
            relativenumber: '',
            dob: getFormattedDate(new Date()),
            provider: this.props.currentUserInfo,
            phone: '',
            age: calculateAge(new Date('2000', 1, 1)),
            email: '',
            addPatientError: false,
            addPatientErrorMessage: ' ',
        };
    }
  handleDateChange = (date) => {
      console.log('DOB===>', getFormattedDate(date));
      this.setState({ dob: getFormattedDate(date), age: calculateAge(date) });
  };

  componentDidMount = () => {
      const { centralStore } = this.props;
      when(
          () => centralStore.currentPatientDataForEditValue,
          () => {
              this.setState(centralStore.currentPatientDataForEditValue);
          }
      );
  };

  onAddPatientChange = (event) => {
      event.preventDefault();
      this.setState({
          [event.target.name]: event.target.value,
          addPatientErrorMessage: ' ',
      });
  };

  onPatientSubmit = async () => {
      const { centralStore } = this.props;
      console.log(' this.props.currentUserInfo', this.props.currentUserInfo);
      let patientdata = {};
      let filterValue = {
          startDate: getDateDaysBefore(30),
          endDate: getDateDaysBefore(0),
      };
      patientdata = this.state;
      console.log(this.state);
      if (checkAllFieldsValid(patientdata) === true) {
          centralStore.showLoader();
          if (this.state.dob === 'NaN/NaN/NaN') {
              alert('Please select a valid date');
          } else {
              if (patientdata.patient_id && patientdata.physician_id) {
                  updatePatient(patientdata).then(async (response) => {
                      console.log('Update Response', response);
                      centralStore.setPatientDataForEdit(undefined);
                      centralStore.hideAddEditDialog();
                      await setPatientTableWithFilter(filterValue);
                  });
              } else {
                  centralStore.setSelectedPatientData(patientdata);
                  console.log('patientdata',patientdata);
                  addPatient(patientdata)
                      .then(async (response) => {
                          console.log('ADD Response', response);
                          centralStore.setPatientDataForEdit(undefined);
                          centralStore.hideAddEditDialog();
                          centralStore.setHasMore(true);
                          await setPatientTableWithFilter(filterValue);
                      })
                      .catch((error) => {
                          error.then((errorressponse) => {
                              console.log('errorressponse', errorressponse.message);
                              this.setState({
                                  addPatientErrorMessage: errorressponse.message,
                              });
                          });
                      });
              }
          }
      } else {
          this.setState({ addPatientError: true });
      }
  };

  render() {
      const { centralStore } = this.props;
      return (
          <div>
              <Dialog
                  open={centralStore.currentopenDialogValue}
                  onClose={() => {
                      centralStore.setPatientDataForEdit(undefined);
                      centralStore.hideAddEditDialog();
                      centralStore.hideLoader();
                  }}
                  className="dialog-box"
              >
                  <DialogTitle className="modal-header">
                      {centralStore.currentPatientDataForEditValue
                          ? 'Edit Patient'
                          : 'Add Patient'}
                  </DialogTitle>
                  <DialogContent className="dialog-content">
                      <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                              <TextField
                                  error={
                                      !this.state.firstName.length && this.state.addPatientError
                                  }
                                  helperText={
                                      !this.state.firstName.length && this.state.addPatientError
                                          ? 'Field Cannot be Empty'
                                          : ''
                                  }
                                  name="firstName"
                                  value={this.state.firstName}
                                  onChange={this.onAddPatientChange}
                                  label="First Name"
                                  variant="outlined"
                                  fullWidth={true}
                                  size="small"
                              />
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <TextField
                                  error={
                                      !this.state.lastName.length && this.state.addPatientError
                                  }
                                  helperText={
                                      !this.state.lastName.length && this.state.addPatientError
                                          ? 'Field Cannot be Empty'
                                          : ''
                                  }
                                  name="lastName"
                                  value={this.state.lastName}
                                  onChange={this.onAddPatientChange}
                                  label="Last Name"
                                  variant="outlined"
                                  fullWidth={true}
                                  size="small"
                              />
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <TextField
                                  error={!this.state.email.length && this.state.addPatientError}
                                  helperText={
                                      !this.state.email.length && this.state.addPatientError
                                          ? 'Field Cannot be Empty'
                                          : ''
                                  }
                                  required
                                  name="email"
                                  value={this.state.email}
                                  onChange={this.onAddPatientChange}
                                  label="Email Id"
                                  variant="outlined"
                                  fullWidth={true}
                                  size="small"
                              />
                              {this.state.addPatientErrorMessage &&
                this.state.addPatientErrorMessage.length > 1 &&
                this.state.addPatientErrorMessage.includes('Email') ? (
                                      <p style={{ color: 'red' }}>
                                          {this.state.addPatientErrorMessage}
                                      </p>
                                  ) : null}
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                      className="outlined-date-picker"
                                      fullWidth={true}
                                      inputVariant="outlined"
                                      size="small"
                                      variant="inline"
                                      format="MM/dd/yyyy"
                                      id="date-picker-inline"
                                      maxDate={getFormattedDate(new Date())}
                                      maxDateMessage="Birth Date cannot exceed today"
                                      label="Date of birth"
                                      value={
                                          this.state.dob === 'mm/dd/yyyy'
                                              ? '01/01/2000'
                                              : this.state.dob
                                      }
                                      onChange={(value) => {
                                          this.handleDateChange(value);
                                      }}
                                      autoOk={true}
                                      KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                      }}
                                  />
                              </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <TextField
                                  error={!this.state.age.length && this.state.addPatientError}
                                  helperText={
                                      !this.state.age.length && this.state.addPatientError
                                          ? 'Field Cannot be Empty'
                                          : ''
                                  }
                                  name="age"
                                  value={this.state.age}
                                  onChange={this.onAddPatientChange}
                                  label="Age"
                                  disabled={true}
                                  variant="outlined"
                                  fullWidth={true}
                                  type="number"
                                  size="small"
                              />
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <TextField
                                  error={!this.state.phone.length && this.state.addPatientError}
                                  helperText={
                                      !this.state.phone.length && this.state.addPatientError
                                          ? 'Field Cannot be Empty'
                                          : ''
                                  }
                                  name="phone"
                                  value={this.state.phone}
                                  onChange={this.onAddPatientChange}
                                  label="Phone"
                                  type="tel"
                                  variant="outlined"
                                  fullWidth={true}
                                  size="small"
                              />
                              {this.state.addPatientErrorMessage &&
                this.state.addPatientErrorMessage.length > 1 &&
                this.state.addPatientErrorMessage.includes('Phone') ? (
                                      <p style={{ color: 'red' }}>
                                          {this.state.addPatientErrorMessage}
                                      </p>
                                  ) : null}
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <TextField
                                  error={
                                      !this.state.provider.name.length &&
                    this.state.addPatientError
                                  }
                                  helperText={
                                      !this.state.provider.name.length &&
                    this.state.addPatientError
                                          ? 'Field Cannot be Empty'
                                          : ''
                                  }
                                  name="provider"
                                  disabled={true}
                                  value={this.state.provider.name}
                                  onChange={this.onAddPatientChange}
                                  label="Provider"
                                  variant="outlined"
                                  fullWidth={true}
                                  size="small"
                              />
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <TextField
                                  error={
                                      !this.state.relativename.length && this.state.addPatientError
                                  }
                                  helperText={
                                      !this.state.relativename.length && this.state.addPatientError
                                          ? 'Field Cannot be Empty'
                                          : ''
                                  }
                                  name="relativename"
                                  value={this.state.relativename}
                                  onChange={this.onAddPatientChange}
                                  label="Relative Name"
                                  variant="outlined"
                                  fullWidth={true}
                                  size="small"
                              />
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <TextField
                                  error={
                                      !this.state.relativenumber.length && this.state.addPatientError
                                  }
                                  helperText={
                                      !this.state.relativenumber.length && this.state.addPatientError
                                          ? 'Field Cannot be Empty'
                                          : ''
                                  }
                                  name="relativenumber"
                                  value={this.state.relativenumber}
                                  onChange={this.onAddPatientChange}
                                  label="Relative Number"
                                  variant="outlined"
                                  fullWidth={true}
                                  size="small"
                              />
                          </Grid>
                      </Grid>
                  </DialogContent>
                  <DialogActions className="modal-footer">
                      <button
                          onClick={() => {
                              centralStore.hideAddEditDialog();
                              centralStore.setPatientDataForEdit(undefined);
                              centralStore.hideLoader();
                          }}
                          className="btn btn-secondary"
                      >
              Close
                      </button>
                      <button onClick={this.onPatientSubmit} className="btn btn-primary">
              Submit
                      </button>
                  </DialogActions>
              </Dialog>
          </div>
      );
  }
}

export default inject('centralStore')(observer(AddEditPatientDialog));
