import { get, post, put, deleteapi } from './api';
import { baseURL } from './api-config';
import { StoreInstance } from '../centralstore';

export const addNote = async (notedetails) => {
    console.log('notesDetails===>', notedetails);
    const body = {
        url: `${baseURL}/cn/notes`,
        data: notedetails,
    };
    await post(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':' + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ': ' + response.statusText
                );
            }
            throw response.json();
        }
    });
};
export const getNotesById = async (patientId) => {
    console.log('note',patientId);
    const body = {
        url: `${baseURL}/cn/notebyid?patient_id=${patientId}`,
        headers: {},
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':' + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            }
        }
    });
};

export const getArchiveNotesById = async (patientId) => {
    console.log('note',patientId);
    const body = {
        url: `${baseURL}/cn/notebyid?patient_id=${patientId}`,
        headers: {},
    };
    return await get(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':' + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            }
        }
    });
};

export const updateNote = async (noteDetails) => {
    console.log('notesDetails===>', noteDetails);
    const body = {
        url: `${baseURL}/cn/notes`,
        data: noteDetails,
        headers: {},
    };
    await put(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':'  + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage +  ':' + response.statusText
                );
            }
        }
    });
};

export const deleteNote = async (noteDetails) => {
    console.log('notesDetails===>', noteDetails);
    const body = {
        url: `${baseURL}/cn/delete`,
        data: noteDetails,
        headers: {},
    };
    await deleteapi(body).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            var errorMessage = '';
            if (response.status >= 500) {
                errorMessage = 'Error From API Side. Report to Back End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ':' + response.statusText
                );
            } else {
                errorMessage = 'Error From UI Side. Report to Front End Developer...!';
                StoreInstance.errorHasOccured(
                    errorMessage + ' : ' + response.statusText
                );
            }
        }
    });
};
